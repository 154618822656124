<template>
	<div id="clients">
		<div v-if="!$store.state.current_client.id && !$store.state.loadClient" class="colonne-gauche">
			<div class="wrapper">
				<div class="top">
					<div class="left">
						<div class="count">
							Bonjour <span>{{ $store.state.user.fields.prenom }}</span>
						</div>
						<div class="title">
							Clients
							<div class="refresh_client" @click="refresh_client()">
								<i class="fal fa-sync"></i>
							</div>
						</div>
					</div>
					<div class="right">
						<van-notice-bar
							v-if="message"
							mode="closeable"
							background="#F9FCFF"
							color="#03a9f4"
							speed="80"
							scrollable
							:text="`${message.titre} : ${message.contenu}`"
						/>
					</div>
				</div>

				<div class="partie-gauche">
					<DaTable v-if="$store.state.fetch_client_ended" :filtre="filtre_actif" />
					<div class="loading" v-if="!$store.state.fetch_client_ended && $store.state.user.fields.role === 'atc'">
						<i class="fal fa-spin fa-spinner"></i>
						<p>Récupération de vos clients et prospects. Merci de patientez.</p>
					</div>

					<div class="begin" v-if="$store.state.user.fields.role != 'atc' && !fetchStatus && !$store.state.loadClientsSpecial">
						<i class="fal fa-long-arrow-left"></i>Choisissez d'abord <strong>un secteur commercial</strong> sur la gauche de l'écran
					</div>

					<ValidationClassification v-if="!$store.state.current_client.id && $store.state.user.fields.role === 'administrateur' && !$store.state.loadClient" />

					<div class="loading" v-if="$store.state.user.fields.role != 'atc' && $store.state.loadClientsSpecial">
						<i class="fal fa-spin fa-spinner"></i>
						<p>Récupération de vos clients et prospects. Merci de patientez.</p>
					</div>
				</div>
				<div class="partie-droite">
					<div class="tuile statistiques" v-if="allClients.length >= 1">
						<img class="icone" src="../assets/images/logos/icone-excel.png" title="" alt="" />
						<h3 style="color: #107c41">Chiffres clés</h3>
						<div class="chiffre gris" @click="filtre_actif = ''">
							<span class="lechiffre">{{ allClients.length }}</span>
							<div class="droite">
								<span>clients totaux</span>
								<span class="pourcent">Tout status confondus</span>
							</div>
						</div>
						<!-- <div class="chiffre orange" @click="filtre_actif = 'validation'">
							<span class="lechiffre">{{ a_valider }}</span>
							<div class="droite">
								<span>client(s) à valider</span>
								<span class="pourcent">En attente de validation </span>
							</div>
						</div> -->
						<div class="chiffre vert_clair" @click="filtre_actif = 'pret'">
							<span class="lechiffre">{{ pret_a_signer }}</span>
							<div class="droite">
								<span>client(s) à signer</span>
								<span class="pourcent">Validés par votre DR ou RRV</span>
							</div>
						</div>
						<!-- <div class="chiffre rouge" @click="filtre_actif = 'refuse'">
							<span class="lechiffre">{{ refuse }}</span>
							<div class="droite">
								<span>client(s) refusés</span>
								<span class="pourcent">Soit {{ Math.round((refuse / allClients.length) * 100) || 0 }}% de vos clients</span>
							</div>
						</div> -->
						<div class="chiffre violet" @click="filtre_actif = 'envoye'">
							<span class="lechiffre">{{ envoye }}</span>
							<div class="droite">
								<span>contrat(s) en signature</span>
								<span class="pourcent">La procédure est inachevée</span>
							</div>
						</div>
						<div class="chiffre " @click="filtre_actif = 'signe'">
							<span class="lechiffre">{{ signed }}</span>
							<div class="droite">
								<span>contrat(s) signés</span>
								<span class="pourcent">{{ Math.round((signed / potentiel) * 100) || 0 }}% des clients signables</span>
							</div>
						</div>
						<router-link to="/statistiques" class="download">Mes statistiques</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="barre" v-if="currentClient.id">
				<div class="start">
					<h2>
						{{ currentClient.fields["Société"] }}
						<span>{{ currentClient.fields["Numéro client"] }}</span>
					</h2>
				</div>
				<div class="end">
					<!-- <div
						class="change_classification"
						v-if="$store.state.user.fields.role === 'atc' || $store.state.user.fields.role === 'rrv'"
						@click="show_form_classification = !show_form_classification"
					>
						<i class="fal fa-random"></i> Changer la classification
					</div> -->
					<!-- <div class="change_statut" v-if="show_form_classification && ($store.state.user.fields.role === 'atc' || $store.state.user.fields.role === 'rrv')">
						<div class="close_classification" @click="show_form_classification = false">
							<i class="fal fa-times"></i>
						</div>
						<h3>Changement de classification</h3>
						<p>
							Merci de remplir le formulaire suivant. <br />Tous les champs sont obligatoires. <br />Votre demande sera ensuite étudiée par un des
							administrateurs.
						</p>
						<form @submit.prevent="handleChangeStatutRequest">
							<div class="form-group">
								<label for="statut_souhaite">Classification souhaitée</label>
								<select id="statut_souhaite" v-model="statut_souhaite" required>
									<option value="" disabled>Choisissez...</option>
									<option v-if="currentClient.fields['Classification (Matrice)'] != 'Basic'" value="Basic">Basic</option>
									<option v-if="currentClient.fields['Classification (Matrice)'] != 'Silver'" value="Silver">Silver</option>
									<option v-if="currentClient.fields['Classification (Matrice)'] != 'Gold'" value="Gold">Gold</option>
									<option v-if="currentClient.fields['Classification (Matrice)'] != 'Black'" value="Black">Black</option>
								</select>
							</div>
							<div class="form-group">
								<label for="motif">Motif</label>
								<textarea
									id="motif"
									v-model="motif_changement"
									required
									placeholder="Expliquez ici la motivation du changement de classification"
								></textarea>
							</div>
							<button type="submit" :disabled="statut_souhaite.length === 0 || motif_changement.length < 10">Envoyer</button>
						</form>
					</div> -->
					<div class="close_client" @click="reset_currentClient">
						<i class="fal fa-long-arrow-left"></i>
						Sauvegarder et quitter
					</div>
				</div>
			</div>
			<div class="principal">
				<div class="begin" v-if="!$store.state.current_client.id && !$store.state.loadClient">
					Sélectionnez un client pour commencer.
				</div>

				<client v-if="$store.state.current_client.id"></client>
				<div v-if="$store.state.loadClient" class="load_client">
					<i class="fal fa-spin fa-spinner"></i>
					<p>Récupération des informations sur le client. <br />Veuillez patientez</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Notify, NoticeBar } from "vant";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Client from "../components/client";
import DaTable from "../components/datatable-client.vue";
import ValidationClassification from "../components/validation_classification.vue";
export default {
	name: "Clients",
	components: { Client, DaTable, ValidationClassification },
	data() {
		return {
			client_search: "",
			classes: "all-classes",
			statut: "all-status",
			filtre_actif: "",
			statut_souhaite: "",
			motif_changement: "",
			show_form_classification: false
		};
	},
	mounted() {
		this.$store.dispatch("getFormations");
		if (this.$store.state.user.fields.role != "atc") {
			this.$store.dispatch("getATC", this.$store.state.user.fields.secteur);
		} else {
			this.$store.dispatch("getClients", {
				force: true
			});
			this.$store.dispatch("getSuspects", {
				force: false
			});
		}
		this.$store.dispatch("getMessage");
		this.$store.dispatch("getChartes");
		this.$store.dispatch("getDocuments");
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
		document.body.scroll({ top: 0 });
	},
	computed: {
		...mapGetters({
			currentClient: "currentClient",
			fetchStatus: "fetchStatus",
			allClients: "allClients",
			message: "message"
		}),
		signed() {
			let signes = this.allClients.filter(client => client.fields.statut === "Signé");
			return signes.length || 0;
		},
		pret_a_signer() {
			let pret_a_signer = this.allClients.filter(client => client.fields.statut === "Prêt à signer");
			return pret_a_signer.length || 0;
		},
		refuse() {
			let refuse = this.allClients.filter(client => client.fields.statut === "Refusé");
			return refuse.length || 0;
		},
		envoye() {
			let envoye = this.allClients.filter(client => client.fields.statut === "Envoyé");
			return envoye.length || 0;
		},
		a_valider() {
			let valider = this.allClients.filter(client => client.fields.statut === "En attente de validation");
			return valider.length || 0;
		},
		potentiel() {
			return this.a_valider + this.envoye + this.pret_a_signer;
		}
	},
	methods: {
		...mapActions({
			askForClassificationChange: "askForClassificationChange"
		}),
		...mapMutations({
			reset_currentClient: "reset_currentClient",
			reset_fetch_client_ended: "reset_fetch_client_ended"
		}),
		refresh_client() {
			this.$store.dispatch("getClients", {
				force: true
			});
		},
		selectClientSelect(e) {
			if (e.target.value != "") {
				this.$store.dispatch("chooseClient", e.target.value);
			}
		},
		selectClient(e, id) {
			document.querySelectorAll(".client.selected").forEach(el => {
				el.classList.remove("selected");
			});
			e.currentTarget.classList.add("selected");
			this.$store.dispatch("chooseClient", id);
		},
		searchClient() {
			if (this.client_search.length > 1) {
				let term = this.client_search
					.toLowerCase()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "");
				let clients = document.querySelectorAll(".client");
				clients.forEach(el => {
					let societe = el.querySelector(".nom");
					let numero = el.querySelector(".numero");
					societe = societe.innerText
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "");
					numero = numero.innerText
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "");
					if (societe.includes(term) || numero.includes(term)) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			} else {
				let clients = document.querySelectorAll(".client");
				clients.forEach(el => {
					el.classList.remove("hidden");
				});
			}
		},
		handleChangeStatutRequest() {
			this.askForClassificationChange({
				id_client: this.currentClient.id,
				nouveau_statut: this.statut_souhaite,
				motif_changement: this.motif_changement,
				utilisateur: this.$store.state.user.id
			})
				.then(() => {
					this.statut_souhaite = "";
					this.motif_changement = "";
					Notify({ type: "success", message: "Votre demande de changement a bien été envoyée. Elle sera traitée par un administrateur prochainement." });
					setTimeout(() => {
						this.show_form_classification = false;
					}, 3000);
				})
				.catch(() => {
					Notify({ type: "danger", message: "Votre demande n'a pas pu être envoyée." });
					setTimeout(() => {
						this.show_form_classification = false;
					}, 3000);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
#clients {
	width: 100%;
	background: white;
	flex: 1;
	overflow-y: auto;
	height: 100vh;
	-webkit-overflow-scrolling: touch;
	display: flex;
	@media screen and (max-width: $screen-phone) {
		height: calc(100vh - 50px);
		overflow-x: hidden;
		display: block;
		position: relative;
		z-index: 8888;
	}
	.colonne-gauche {
		flex: 0 0 auto;
		width: calc(100vw - 230px);
		padding: 0;
		display: flex;
		flex-direction: row;
		height: 100vh;
		position: relative;

		.wrapper {
			width: 100%;
			display: flex;
			max-width: 1400px;
			margin: 0 auto;
			padding: 30px;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
			gap: 40px;

			.top {
				flex: 0 0 auto;
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 50px;
				@media screen and (max-width: 1500px) {
					order: 1;
				}
				.left {
					flex: 0 0 auto;
					.title {
						font-size: 56px;
						line-height: 50px;
						font-weight: 500;
						display: inline-block;
						margin-bottom: 3px;
						padding-left: 16px;
						flex: 0 0 auto;
						position: relative;
						@media screen and (max-width: $screen-phone) {
							padding-left: 10px;
							margin-top: 10px;
						}
						.refresh_client {
							font-size: 16px;
							position: absolute;
							bottom: -12px;
							right: -30px;
							@media screen and (max-width: $screen-phone) {
								top: 16px;
								right: 16px;
							}
						}
					}
					.count {
						font-size: 18px;
						line-height: 14px;
						font-weight: 300;
						flex: 0 0 auto;
						color: slategray;
						padding-left: 16px;
						padding-bottom: 10px;
					}
				}
				.right {
					flex: 1;
					padding-left: 60px;
					.van-notice-bar__wrap {
						border-radius: 4px;
					}
				}
			}
			.partie-gauche {
				flex: 2;
				@media screen and (max-width: 1500px) {
					order: 3;
					flex: 0 0 100%;
				}
				.title {
					font-size: 56px;
					line-height: 50px;
					font-weight: 500;
					display: inline-block;
					margin-bottom: 3px;
					padding-left: 16px;
					flex: 0 0 auto;
					margin-bottom: 60px;
					position: relative;
					@media screen and (max-width: $screen-phone) {
						padding-left: 10px;
						margin-top: 10px;
					}
					.refresh_client {
						font-size: 16px;
						position: absolute;
						bottom: -12px;
						right: -30px;
						@media screen and (max-width: $screen-phone) {
							top: 16px;
							right: 16px;
						}
					}
				}
				.count {
					font-size: 18px;
					line-height: 14px;
					font-weight: 300;
					flex: 0 0 auto;
					color: slategray;
					padding-left: 16px;
					padding-bottom: 10px;
				}
				.begin {
					padding: 20px;
					display: flex;
					flex-direction: column;
					font-size: 16px;
					font-weight: 300;
					line-height: 26px;
					background: rgba($bleu, 0.03);
					max-width: 360px;
					border-radius: 30px;
					padding: 30px;
					i,
					svg {
						margin-bottom: 20px;
						font-size: 26px;
					}
					strong {
						font-weight: 500;
						font-size: 18px;
						color: $bleu;
					}
				}
			}
			.partie-droite {
				flex: 0.7;
				padding-top: 30px;
				@media screen and (max-width: 1500px) {
					padding: 15px;
					order: 2;
					flex: 0 0 100%;
				}
				.tuile {
					transition: all 0.3s ease;
					border-radius: 20px;
					position: relative;
					padding: 30px;
					box-shadow: 0 0 40px rgba(#107c41, 0.1);
					margin-bottom: 30px;
					@media screen and (max-width: 1500px) {
						display: flex;
						flex-wrap: wrap;
					}
					&:hover {
						box-shadow: 0 0 50px rgba(#107c41, 0.3);
					}
					h3 {
						text-align: right;
						font-weight: 500;
						font-size: 24px;
						text-transform: none;
						margin-bottom: 20px;
						@media screen and (max-width: 1500px) {
							flex: 0 0 100%;
						}
					}
					.icone {
						position: absolute;
						top: 0;
						left: 0;
						width: 100px;
						height: auto;
						transform: translate(20px, -50%);
						border-radius: 50%;
						box-shadow: 4px 4px 20px rgba($vert, 0.05);
					}
					.chiffre {
						display: flex;
						margin-bottom: 4px;
						display: flex;
						align-items: center;
						gap: 20px;
						padding: 10px 0;
						border-radius: 2px;
						border-left: solid 2px $vert;
						background: rgba($vert, 0.04);
						cursor: pointer;
						transition: all 0.3s ease;
						@media screen and (max-width: 1500px) {
							flex-direction: column;
							flex: 0 0 auto;
							width: 25%;
							padding-top: 20px;
							padding-bottom: 20px;
							text-align: center;
							justify-content: center;
							align-self: flex-start;
						}
						.lechiffre {
							color: #107c41;
							font-weight: 500;
							font-size: 38px;
							flex: 0 0 60px;
							text-align: right;
							@media screen and (max-width: 1500px) {
								flex: auto;
								font-size: 30px;
								line-height: 30px;
							}
						}
						.droite {
							display: flex;
							flex-direction: column;
							font-weight: 500;
							font-size: 15px;
							line-height: 18px;
							@media screen and (max-width: 1500px) {
								font-size: 13px;
								line-height: 16px;
								padding: 0 10px;
							}
							.pourcent {
								font-size: 12px;
								font-weight: 300;
								color: slategray;
								@media screen and (max-width: 1500px) {
									font-size: 10px;
									line-height: 12px;
								}
							}
						}
						&.orange {
							border-left-color: #fff9c4;
							background: rgba(#fff9c4, 0.1);
							.lechiffre {
								color: #ff8f00;
							}
						}
						&.vert_clair {
							border-left-color: #dcedc8;
							background: rgba(#dcedc8, 0.1);
							.lechiffre {
								color: #558b2f;
							}
						}
						&.rouge {
							border-left-color: #ffcdd2;
							background: rgba(#ffcdd2, 0.1);
							.lechiffre {
								color: #c62828;
							}
						}
						&.violet {
							border-left-color: rgba($bleu, 0.2);
							background: rgba($bleu, 0.03);
							.lechiffre {
								color: $bleu;
							}
						}
						&.gris {
							border: none;
							background: none;
							.lechiffre {
								color: black;
							}
						}
						&:hover {
							border-left-width: 5px;
						}
					}
					.download {
						padding: 3px 0;
						appearance: none;
						outline: none;
						border: none;
						color: $vert;
						font-weight: 600;
						font-size: 13px;
						background: white;
						position: relative;
						text-align: center;
						display: block;
						margin-top: 30px;
						width: 100%;
						transition: all 0.3s ease;
						&:hover {
							color: black;
						}
						@media screen and (max-width: 1500px) {
							flex: 0 0 100%;
						}
					}
				}
			}
		}

		&.masked {
			display: none;
		}
		@media screen and (max-width: $screen-phone) {
			width: 100%;
			height: auto;
			padding: 16px;
			display: block;
		}
		.client_select {
			color: $black;
			background: rgba(black, 0.05);
			min-height: 30px;
			appearance: none;
			padding: 4px 10px;
			width: 100%;
			border: none;
			border-radius: 4px;
			margin-bottom: 4px;
			margin-right: 4px;
			outline: none;
		}

		.phoneonly {
			display: none;
			@media screen and (max-width: $screen-phone) {
				display: block;
			}
		}
		.liste_clients {
			display: flex;
			flex-direction: column;
			padding: 10px 10px 10px 0;
			flex: 1;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			@media screen and (max-width: $screen-phone) {
				height: auto;
				display: none;
			}
			.client {
				padding: 10px 5px 10px 20px;
				color: $black;
				transition: all 0.3s ease;
				position: relative;
				margin-top: 2px;
				margin-bottom: 2px;
				border-bottom: solid 1px rgba(black, 0.05);
				border-radius: 4px;
				cursor: pointer;
				@media screen and (max-width: $screen-phone) {
					padding: 10px 0px 10px 20px;
				}
				&:hover,
				&.selected {
					background: lighten($rouge, 5%);
					color: white;
					border-bottom-color: transparent;

					.classe {
						color: white;
					}
					&.brouillon {
						&:before {
							background: darken($rouge, 15%);
						}
					}
				}
				&:before {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: $black;
					left: 5px;
					top: 14px;
				}
				&.hidden {
					display: none;
				}
				.nom {
					font-size: 12px;
					line-height: 16px;
					font-weight: bold;
					margin-bottom: 2px;
					padding-right: 40px;
				}
				.numero {
					font-size: 13px;
					font-weight: 500;
					margin-bottom: 2px;
				}
				.data {
					font-size: 11px;
					line-height: 14px;
					font-weight: normal;
				}
				.classe {
					position: absolute;
					right: 10px;
					top: 11px;
					font-size: 11px;
					line-height: 14px;
					color: grey;
					font-weight: 300;
				}
				&.brouillon {
					&:before {
						background: $rouge;
					}
				}
				&.envoyé {
					&:before {
						background: lightblue;
					}
				}
				&.signé {
					&:before {
						background: lightseagreen;
					}
				}
			}
		}
	}
	.content {
		flex: 1;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		@media screen and (max-width: $screen-phone) {
			height: auto;
		}
		.barre {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: solid 1px rgba(black, 0.05);
			padding: 20px;
			@media screen and (max-width: $screen-phone) {
				display: none;
			}
			h2 {
				font-size: 20px;
				font-weight: 500;
				display: flex;
				flex-direction: column;
				text-transform: none;
				&::first-letter {
					text-transform: uppercase;
				}
				span {
					font-size: 14px;
					font-weight: 300;
				}
			}
			.end {
				display: flex;
				align-items: center;
				gap: 10px;
				.change_classification {
					align-self: flex-end;
					background: rgba($fond, 0.2);
					color: darken($fond, 20%);
					padding: 8px 16px;
					font-size: 14px;
					font-weight: 500;
					border-radius: 4px;
					display: flex;
					align-items: flex;
					gap: 6px;
					letter-spacing: 0.05px;
					cursor: pointer;
					i,
					svg {
						font-size: 14px;
						transform: translateY(2px);
						transition: all 0.3s ease;
					}
					&:hover {
						background: $bleu;
						color: white;
						i,
						svg {
							margin-right: 5px;
						}
					}
				}
				.change_statut {
					animation: appearFromBottom 0.3s ease;
					position: fixed;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 99;
					background: lighten($fond, 80%);
					padding: 50px 50px;
					min-width: 500px;
					border-radius: 30px;
					box-shadow: 10px 10px 200px rgba($fond, 0.8);
					.close_classification {
						position: absolute;
						top: 30px;
						right: 30px;
						color: $fond;
						font-size: 18px;
						cursor: pointer;
						&:hover {
							color: $bleu;
						}
					}
					h3 {
						font-size: 24px;
						margin: 0 0 20px 0;
						font-weight: 500;
					}
					p {
						font-size: 13px;
					}
					form {
						margin-top: 30px;
						.form-group {
							display: block;
							margin-bottom: 10px;
							label {
								font-size: 10px;
								text-transform: uppercase;
								margin-bottom: 4px;
								font-weight: 500;
								display: block;
							}
							textarea {
								padding: 10px;
								background: rgba($fond, 0.1);
								border: none;
								outline: none;
								display: block;
								width: 100%;
								border: solid 1px $fond;
								font-size: 13px;
								font-weight: 500;
								min-height: 100px;
							}
							select {
								width: 100%;
								height: 32px;
								border: none;
								padding: 0 10px;
								background: rgba($fond, 0.1);
								font-weight: 500;
								border: solid 1px $fond;
								font-size: 13px;
								font-weight: 500;

								&:focus,
								&:hover {
									outline: solid 4px rgba($bleu, 0.1);
								}
							}
						}
						button[type="submit"] {
							background: $bleu;
							color: white;
							border: none;
							font-size: 10px;
							font-weight: bold;
							text-transform: uppercase;
							padding: 0 20px;
							height: 30px;
							float: right;
							cursor: pointer;
							transition: all 0.3s ease;
							&:disabled {
								background: $fond;
								color: darken($fond, 30%);
								opacity: 0.4;
							}
							&:hover {
								background: darken($bleu, 10%);
							}
						}
					}
				}
				.close_client {
					align-self: flex-end;
					background: $rouge;
					color: white;
					padding: 8px 16px;
					font-size: 14px;
					font-weight: 500;
					border-radius: 4px;
					display: flex;
					align-items: flex;
					gap: 6px;
					letter-spacing: 0.05px;
					cursor: pointer;
					i,
					svg {
						font-size: 14px;
						transform: translateY(2px);
						transition: all 0.3s ease;
					}
					&:hover {
						background: darken($rouge, 10%);
						i,
						svg {
							margin-right: 5px;
						}
					}
				}
			}
		}
		.principal {
			height: calc(100vh - 51px);
			overflow-y: auto;
			width: 100%;
			-webkit-overflow-scrolling: touch;
			@media screen and (max-width: $screen-phone) {
				height: auto;
			}
			.load_client {
				width: 100%;
				padding: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				flex-direction: column;
				i,
				svg {
					font-size: 20px;
					margin-bottom: 20px;
				}
			}
			.begin {
				padding: 100px;
				text-align: center;
				font-size: 13px;
				font-weight: bold;
			}
		}
	}
	.loading {
		font-size: 12px;
		padding: 20px;
		i,
		svg {
			font-size: 20px;
		}
		p {
			margin: 10px 0;
			max-width: 300px;
		}
		&.loading_special {
			@media screen and (max-width: $screen-phone) {
				position: fixed;
				bottom: 30px;
				i.fal {
					transform: rotateZ(-90deg);
				}
			}
		}
	}
}
</style>

<template>
	<nav id="navigation">
		<User />
	</nav>
</template>
<script>
import User from "../components/user";

export default {
	name: "mainNav",
	components: { User },
	data() {
		return {};
	},
	methods: {}
};
</script>

<style lang="scss">
#navigation {
	background: white;
	color: $black;
	height: 100vh;
	font-size: 13px;
	transition: all 0.3s ease;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 230px;
	flex: 0 0 auto;
	@media screen and (max-width: $screen-phone) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 50px;
		background: white;
		border-bottom: solid 1px rgba(black, 0.05);
		z-index: 999;
		overflow-y: visible;
	}
}
</style>

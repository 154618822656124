<template>
	<div class="page" id="dashboard">
		<mainNav />
		<Clients />
	</div>
</template>
<script>
import mainNav from "../components/mainNav";
import Clients from "../components/clients";
export default {
	name: "Dashboard",
	components: { mainNav, Clients },
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss">
#dashboard {
	.content {
		flex: 1;
		height: 100vh;
		padding: 0px;
		display: flex;
		flex-direction: column;
		.load_client,
		.load_suspect {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 auto;
			text-align: center;
			display: flex;
			flex-direction: column;
			font-size: 14px;
			background: white;
			border-radius: 11px;
			padding: 40px;
			max-width: 500px;
			margin: 0 auto;
			p {
				margin: 20px;
			}
			i,
			svg {
				font-size: 20px;
			}
		}
	}
	@media screen and (max-width: $screen-phone) {
		padding-top: 50px;
	}
}
</style>

<template>
	<div class="pageprint" id="page6">
		<h2>Conditions générales de vente 2024</h2>

		<div class="deux-colonnes">
			<p><strong>I. G&eacute;n&eacute;ralit&eacute;s </strong></p>

			<p>
				1.1. Les conditions g&eacute;n&eacute;rales pr&eacute;sentes r&eacute;gissent uniquement les relations entre la soci&eacute;t&eacute; Viessmann France
				S.A.S., ci-apr&egrave;s d&eacute;sign&eacute;e &ldquo;le Vendeur&ldquo;, et tout professionnel auquel elle vend son Mat&eacute;riel, ci-apr&egrave;s
				d&eacute;sign&eacute; &ldquo;l&rsquo;Acheteur&rdquo;. L&rsquo;Acheteur &eacute;tant exclusivement un professionnel. 
				
			</p>
			<p>Le Vendeur et l&rsquo;Acheteur sont d&eacute;sign&eacute;s ensemble &ldquo;les Parties&ldquo;.</p>
			<p>
				1.2. Les pr&eacute;sentes conditions g&eacute;n&eacute;rales s&rsquo;appliquent &agrave; la vente de mat&eacute;riels de chauffage et de production
				d&rsquo;eau chaude sanitaire, de climatisation, de panneaux solaires, leurs accessoires et les pi&egrave;ces de rechange.
			</p>

			<p>
				1.3. Ces conditions g&eacute;n&eacute;rales pourront &ecirc;tre compl&eacute;t&eacute;es par des conditions sp&eacute;ciales de vente pour certains types de
				Mat&eacute;riel, notamment les g&eacute;n&eacute;rateurs de chaleur de grande puissance. Les prestations de louage de service, telles que les prestations
				intellectuelles (r&eacute;alisation d&rsquo;&eacute;tudes, formations, etc.) ou de maintenance feront l&rsquo;objet de contrats s&eacute;par&eacute;s.
			</p>

			<p>
				1.4. D&eacute;finitions : Au sens des pr&eacute;sentes conditions g&eacute;n&eacute;rales, &ldquo;le Mat&eacute;riel&rdquo; d&eacute;signe les
				mat&eacute;riels de chauffage et de production d&rsquo;eau chaude sanitaire, de climatisation, de panneaux solaires, leurs accessoires et les pi&egrave;ces
				de rechange. &ldquo;Les conditions sp&eacute;ciales&ldquo; d&eacute;signent des conditions applicables &agrave; certains types de Mat&eacute;riel en sus des
				conditions g&eacute;n&eacute;rales pr&eacute;sentes. &ldquo;Conditions particuli&egrave;res&ldquo; d&eacute;signent l&rsquo;ensemble des
				sp&eacute;cifications contractuelles contenues dans la commande et la confirmation de commande, telles que la d&eacute;signation du Mat&eacute;riel
				command&eacute;, les d&eacute;lais de livraison, le prix convenu et toutes les prestations suppl&eacute;mentaires souhait&eacute;es par l&rsquo;Acheteur.
				&ldquo;Le Client&ldquo; d&eacute;signe le cocontractant de l&rsquo;Acheteur et g&eacute;n&eacute;ralement l&rsquo;utilisateur final du Mat&eacute;riel, si
				celui-ci lui a &eacute;t&eacute; revendu par l&rsquo;Acheteur.
			</p>

			<p>
				1.5. Conform&eacute;ment &agrave; l&rsquo;article L.441-6 du Code de commerce les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente constituent
				la base de l&rsquo;offre du Vendeur, et pr&eacute;valent sur tous documents g&eacute;n&eacute;raux ou particuliers de l&rsquo;Acheteur qui n&rsquo;auraient
				pas &eacute;t&eacute; express&eacute;ment accept&eacute;s par le Vendeur. Toute passation de commande entra&icirc;ne l&rsquo;acceptation sans r&eacute;serve
				des pr&eacute;sentes conditions g&eacute;n&eacute;rales. Par la passation de commande, l&rsquo;Acheteur certifie qu&rsquo;il a la qualit&eacute; de
				professionnel qualifi&eacute; au sens de l&rsquo;article 1.9 ci-dessous.
			</p>

			<p>
				1.6. Sauf stipulation &eacute;crite contraire, les &eacute;ventuelles &eacute;tudes ou recommandations faites par le Vendeur sont donn&eacute;es &agrave;
				titre purement indicatif. Elles n&rsquo;engagent pas la responsabilit&eacute; du Vendeur et ne constituent pas un &eacute;l&eacute;ment
				d&rsquo;ex&eacute;cution. Il appartient &agrave; l&rsquo;Acheteur sous sa propre responsabilit&eacute; de les contr&ocirc;ler et de v&eacute;rifier
				qu&rsquo;elles tiennent compte des r&egrave;gles g&eacute;n&eacute;rales applicables et des conditions particuli&egrave;res de conception et/ou de mise en
				&oelig;uvre.
			</p>

			<p>
				1.7. Font partie int&eacute;grante du contrat et par ordre de priorit&eacute; d&eacute;croissant : - La confirmation de commande par le Vendeur, la commande
				de l&rsquo;Acheteur, ainsi que l&rsquo;ensemble des autres conditions particuli&egrave;res convenues entre les Parties - Les conditions sp&eacute;ciales, si
				elles trouvent application - Les conditions g&eacute;n&eacute;rales pr&eacute;sentes - Les documents du Vendeur compl&eacute;tant les conditions
				g&eacute;n&eacute;rales, sp&eacute;ciales et particuli&egrave;res ou qui sont livr&eacute;s dans le cadre de ses obligations contractuelles - Le bon de
				livraison - La facture - Devis &eacute;tablis &agrave; la demande de l&acute;Acheteur Ne font pas partie du contrat : les documents, catalogues,
				publicit&eacute;s, tarifs non mentionn&eacute;s express&eacute;ment dans les conditions particuli&egrave;res.
			</p>

			<p>
				1.8. Le Vendeur se r&eacute;serve le droit de modifier les conditions g&eacute;n&eacute;rales pr&eacute;sentes &agrave; tout moment en respectant un
				pr&eacute;avis d&rsquo;un mois. Toute commande pass&eacute;e apr&egrave;s ce d&eacute;lai sera soumise aux nouvelles conditions.
			</p>

			<p>
				1.9. L&rsquo;installation des pompes &agrave; chaleur doit &ecirc;tre r&eacute;alis&eacute;e dans le respect des r&egrave;gles de l&rsquo;Art en vigueur,
				existantes ou futures, par des professionnels titulaires de &lsquo;&lsquo;l&rsquo;attestation de capacit&eacute; pour la manipulation des fluides
				frigorig&egrave;nes&rsquo;&rsquo; en cours de validit&eacute;, d&eacute;livr&eacute;e par un organisme accr&eacute;dit&eacute; conform&eacute;ment &agrave;
				l&rsquo;Article R. 543-99 de l&rsquo;Arr&ecirc;t&eacute; du 29 ao&ucirc;t 2008 et &agrave; l&rsquo;article 13 (TITRE IV : dispositions relatives aux
				op&eacute;rateurs) du d&eacute;cret 2007- 737 du 7 mai 2007.
			</p>

			<p>
				1.10. L&rsquo;acheteur d&eacute;clare &ecirc;tre une professionnel qualifi&eacute; de la fili&egrave;re thermique/climatique avec des connaissances
				approfondies de ce domaine ou, si ce n&rsquo;est pas le cas confier l&rsquo;installation &agrave; un professionnel reconnu et qualifi&eacute; de la
				fili&egrave;re thermique/climatique.
			</p>

			<p><strong>II. Offre, commande, formation du contrat</strong></p>

			<p>
				2.1. Caract&eacute;ristiques de l&rsquo;offre Les prix et indications port&eacute;s sur les catalogues, prospectus et tarifs sont donn&eacute;s &agrave;
				titre indicatif, le Vendeur se r&eacute;servant le droit d&rsquo;apporter des modifications de disposition, de forme, de dimensions, de poids ou de
				mati&egrave;re &agrave; ses Mat&eacute;riels dont les illustrations, photographies, descriptions ou sch&eacute;mas d&rsquo;installation figurent sur ses
				imprim&eacute;s de publicit&eacute; ou sur tous autres supports de communication. Sauf stipulation &eacute;crite contraire, les offres ont une
				validit&eacute; de 3 mois &agrave; courir de la date indiqu&eacute;e dans l&rsquo;offre.
			</p>

			<p>
				2.2. Formation du contrat - Acceptation de commande Les commandes doivent &ecirc;tre transmises par &eacute;crit. On entend par &ldquo;&eacute;crit&ldquo;,
				au sens des pr&eacute;sentes conditions g&eacute;n&eacute;rales, tout document &eacute;tabli sur support papier, &eacute;lectronique, par
				t&eacute;l&eacute;copie ou via les logiciels de commande en ligne. Le contrat de vente n&rsquo;est parfait que sous r&eacute;serve d&rsquo;acceptation
				expresse, par le Vendeur, de la commande de l&rsquo;Acheteur, y compris lorsque les offres sont faites par les repr&eacute;sentants ou employ&eacute;s du
				Vendeur. Cette acceptation doit &ecirc;tre adress&eacute;e par &eacute;crit, peu importe la forme de cet &eacute;crit et sa d&eacute;nomination, pourvu
				qu&rsquo;il en ressorte une acceptation non &eacute;quivoque de la commande, dans un d&eacute;lai de 8 jours ouvr&eacute;s. Pass&eacute; ce d&eacute;lai et
				dans le silence du Vendeur, la commande sera r&eacute;put&eacute;e refus&eacute;e. L&rsquo;accus&eacute; de r&eacute;ception de commande fixe les conditions
				effectives applicables &agrave; la commande et, au moins : d&eacute;signation, quantit&eacute;, d&eacute;lai de livraison, prix, conditions de
				r&egrave;glement (&laquo;conditions particuli&egrave;res&raquo;) Les d&eacute;lais de livraison indiqu&eacute;s sur celui-ci sont fermes. Sans retour de la
				part de l&rsquo;Acheteur, la commande est livr&eacute;e &agrave; la date / aux dates indiqu&eacute;es sur la confirmation de commande. Seul
				l&rsquo;accus&eacute; de r&eacute;ception de commande est susceptible d&rsquo;apporter une modification aux dispositions des pr&eacute;sentes Conditions
				G&eacute;n&eacute;rales. Une exception est faite pour les urgences client sur les commandes par t&eacute;l&eacute;phone de pi&egrave;ces
				d&eacute;tach&eacute;es aupr&egrave;s du Centre National de la Pi&egrave;ce de Rechange. Le tra&ccedil;age de la commande en fera la preuve en cas de
				contestation.
			</p>

			<p>
				2.3. Commandes sur appel Les &ldquo;commandes sur appel&rdquo; ou &ldquo;commandes ouvertes&rdquo; sont admises mais le mat&eacute;riel concern&eacute; ne
				sera pas tenu en stock.
			</p>

			<p>
				2.4. Annulation ou modification de commande La commande repr&eacute;sente l&rsquo;acceptation de l&rsquo;offre par l&rsquo;Acheteur et, conform&eacute;ment
				au droit commun, elle est intangible, l&rsquo;Acheteur ne pouvant la retirer ou l&rsquo;annuler, quel qu&rsquo;en soit le motif. Tout versement &agrave; la
				commande est un acompte, d&eacute;finitivement acquis au Vendeur au cas d&rsquo;acceptation de la commande. En cas de refus de la commande par le Vendeur,
				le Vendeur remboursera l&rsquo;acompte vers&eacute; dans les plus brefs d&eacute;lais. Les modifications et adjonctions &agrave; la commande sont soumises
				&agrave; l&rsquo;accord expr&egrave;s du Vendeur, qui fera savoir &agrave; l&rsquo;Acheteur quelles en sont les conditions et les cons&eacute;quences sur
				les conditions du contrat. L&rsquo;annulation d&rsquo;une commande par l&rsquo;Acheteur n&eacute;cessite &eacute;galement l&rsquo;accord expr&egrave;s du
				Vendeur. Dans un tel cas, l&rsquo;Acheteur indemnisera le Vendeur pour tous les frais engag&eacute;s et pour toutes les cons&eacute;quences directes et
				indirectes qui en d&eacute;coulent. En outre, l&rsquo;acompte vers&eacute; restera acquis au Vendeur.
			</p>

			<p>
				2.5. Rupture des stocks Si exceptionnellement le Vendeur n&rsquo;est pas en mesure de livrer le Mat&eacute;riel pour des raisons de rupture de stocks, il en
				informera l&rsquo;Acheteur suffisamment t&ocirc;t. Dans ce cas le Vendeur pourra proposer &agrave; l&rsquo;Acheteur un Mat&eacute;riel &eacute;quivalent. Si
				l&rsquo;Acheteur refuse ce Mat&eacute;riel, la commande sera annul&eacute;e de plein droit et l&rsquo;Acheteur rembours&eacute; de ses acomptes.
				L&rsquo;Acheteur ne pourra pas faire valoir d&rsquo;autres droits que le remboursement de ses acomptes.
			</p>

			<p>
				2.6. Fournitures additionnelles La livraison comprend uniquement le Mat&eacute;riel sp&eacute;cifi&eacute; &agrave; la commande. Pour les fournitures
				additionnelles, les Parties s&rsquo;accorderont express&eacute;ment sur les prix et nouveaux d&eacute;lais. En aucun cas, les conditions
				particuli&egrave;res applicables aux fournitures additionnelles ne peuvent remettre en cause celles de la commande principale.
			</p>

			<p>
				2.7. Remises, ristournes et accords de coop&eacute;ration Les conditions de remises, rabais et ristournes, sont d&eacute;termin&eacute;es par les Conditions
				de vente et de r&egrave;glement du Vendeur, conform&eacute;ment &agrave; l&rsquo;article L. 441-6 du Code de commerce. Il est rappel&eacute; que,
				conform&eacute;ment &agrave; l&rsquo;article L. 442-6 du m&ecirc;me code, est illicite le fait pour un Acheteur de b&eacute;n&eacute;ficier
				r&eacute;troactivement de remises, de ristournes ou d&rsquo;accords de coop&eacute;ration commerciale ou encore le fait d&rsquo;obtenir ou tenter
				d&rsquo;obtenir un avantage quelconque ne correspondant &agrave; aucun service commercial ou prestation effectivement rendue ou manifestement
				disproportionn&eacute; au regard de la valeur du service rendu.
			</p>

			<p>
				2.8. Ouverture et maintien de compte, situation financi&egrave;re Afin de passer une commande l&rsquo;Acheteur n&eacute;cessite un compte client. Le Vendeur
				se r&eacute;serve la facult&eacute; de subordonner l&rsquo;ouverture et le maintien du compte &agrave; l&rsquo;obtention, aupr&egrave;s de l&rsquo;Acheteur,
				de documents comptables, financiers et juridiques et, le cas &eacute;ch&eacute;ant, de garanties. Le Vendeur se r&eacute;serve le droit d&rsquo;exiger le
				paiement total ou partiel du Mat&eacute;riel au moment de la passation de commande, si la situation financi&egrave;re de l&rsquo;Acheteur le justifie.
			</p>

			<p>2.9. Conditions Tarifaires Les Conditions Tarifaires sont remises &agrave; l&rsquo;Acheteur sur simple demande.</p>

			<p>
				2.10. Pour les g&eacute;n&eacute;rateurs de chaleur Vitomax, Vitoplex LS, biomasse gamme Viessmann Holzheiztechnik GmbH, pompes &agrave; chaleur gamme KWT
				et cog&eacute;n&eacute;ration gamme Viessmann Kraft-W&auml;rme-Kopplung GmbH, des conditions sp&eacute;cifiques ont &eacute;t&eacute; &eacute;tablies. Elles
				seront remises &agrave; l&rsquo;Acheteur sur simple demande.
			</p>

			<p><strong>III. Livraison et prix</strong></p>

			<p>
				3.1. Conditions de livraison Sauf stipulation particuli&egrave;re express&eacute;ment convenue entre les Parties, la livraison est r&eacute;put&eacute;e
				effectu&eacute;e au lieu convenu par les Parties, en France m&eacute;tropolitaine. La livraison est effectu&eacute;e aux frais du Vendeur, sauf
				Mat&eacute;riels vis&eacute;s au 2.10 et leurs pi&egrave;ces de rechange. La livraison est effectu&eacute;e par la remise du Mat&eacute;riel &agrave;
				l&rsquo;Acheteur sur le lieu convenu &agrave; la date convenue par le Vendeur ou son transporteur. Il appartient &agrave; l&rsquo;Acheteur de pr&eacute;voir
				et mettre en &oelig;uvre les moyens n&eacute;cessaires au d&eacute;chargement pour toute commande dont le poids total est sup&eacute;rieur &agrave; 1 tonne.
				Si la r&eacute;ception n&rsquo;a pas lieu du fait de l&rsquo;action ou de l&rsquo;inaction de l&rsquo;Acheteur ou pour une cause quelconque,
				ind&eacute;pendante de la volont&eacute; du Vendeur, la livraison est r&eacute;put&eacute;e effectu&eacute;e par simple avis de mise &agrave; disposition.
				Dans ce cas, le Mat&eacute;riel est retourn&eacute; chez le Vendeur aux frais et aux risques de l&rsquo;Acheteur, o&ugrave; il y sera emmagasin&eacute;
				et/ou surveill&eacute; et/ou manutentionn&eacute; s&rsquo;il y a lieu, aux frais et risques de l&rsquo;Acheteur, le Vendeur d&eacute;clinant toute
				responsabilit&eacute; subs&eacute;quente &agrave; cet &eacute;gard. Le contrat devra &ecirc;tre ex&eacute;cut&eacute; sans modification, et notamment les
				obligations de paiement de la fourniture demeurent inchang&eacute;es. Des frais d&rsquo;entreposage et de traitement &agrave; hauteur de 0,5 % de la valeur
				de la commande seront factur&eacute;s pour chaque semaine de retard commenc&eacute;e. L&rsquo;Acheteur aura l&rsquo;obligation de venir
				r&eacute;cup&eacute;rer le Mat&eacute;riel command&eacute; dans les plus brefs d&eacute;lais.
			</p>

			<p>3.2. Le Vendeur est autoris&eacute; &agrave; proc&eacute;der &agrave; des livraisons partielles.</p>
			<p>
				3.3. Toute assistance technique est possible uniquement sur demande écrite expresse de l&rsquo;Acheteur. Le Vendeur met à disposition les documents ou les
				outils pour que la demande puisse être formulée. Sauf accord écrit des Parties, le Vendeur n&rsquo;a aucune obligation d&rsquo;assistance technique,
				d&rsquo;installation ou de maintenance du Matériel.
			</p>
			<p>3.4. Transports, frais, assurance</p>

		<p>En conséquence de la livraison au lieu convenu, toutes les opérations de transport (à l&rsquo;exception du déchargement, lorsque le poids total du Matériel est supérieur à 1 tonne), d&rsquo;assurance et de manutention sont à la charge et aux frais du Vendeur. Des frais de transport sont à la charge de l&rsquo;Acheteur dans les cas suivants :</p>

		<p>- une livraison qui nécessite le transport par un camion hors standard - l&rsquo;Acheteur exige la livraison à un horaire particulier<br />
		- une livraison expresse en moins de 24 heures</p>

		<p>3.5. Réception</p>

		<p>Il appartient à l&rsquo;Acheteur de vérifier sans délai le Matériel à l&rsquo;arrivée et d&rsquo;exercer, s&rsquo;il y a lieu, ses recours contre les transporteurs, même si l&rsquo;expédition a été faite franco. Pour être admises, les réclamations sur la composition, la quantité et le poids du Matériel livré ou sa non-conformité avec le bordereau d&rsquo;expédition doivent être formulées à réception du Matériel sur le bon de livraison et confirmé par lettre recommandée avec accusé de réception au transporteur dans les trois jours de l&rsquo;arrivée du Matériel.</p>

		
		</div>

		<Pieddepage :page="4" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page5",
	components: { Pieddepage },
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page6 {
	.data {
		address {
			text-align: right;
		}
	}
	.deux-colonnes {
		margin-top: 40px;
		column-count: 2;
		column-gap: 100px;
		font-size: 16px;
		line-height: 21px;
		text-align: justify;
		strong {
			margin-top: 10px;
			display: block;
		}
	}
}
</style>

<template>
	<div class="pageprint" id="page3">
		<h1>Volumes réalisés et prévus</h1>
		<table cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th width="40%">Produits VIESSMANN</th>
					<th width="30%">Volumes réalisés en 2023</th>
					<th width="30%">Volumes prévus en 2024</th>
				</tr>
			</thead>
			<tbody>
				<tr class="title">
					<td colspan="3">Pac, Clim et CET</td>
				</tr>
				<tr>
					<td>PAC</td>
					<td>{{ client.fields["PAC"] || 0 }}</td>
					<td>{{ client.fields["pac_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Clim</td>
					<td>{{ client.fields["Clim"] || 0 }}</td>
					<td>{{ client.fields["clim_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Chauffe-eau thermodynamique</td>
					<td>{{ client.fields["Thermodynamique"] || 0 }}</td>
					<td>{{ client.fields["thermodynamique_objectif"] || 0 }}</td>
				</tr>
				<tr class="title">
					<td colspan="3">Chaudières</td>
				</tr>
				<tr>
					<td>Murales</td>
					<td>{{ client.fields["Murales"] || 0 }}</td>
					<td>{{ client.fields["murales_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Sol Gaz</td>
					<td>{{ client.fields["Ch.Gaz"] || 0 }}</td>
					<td>{{ client.fields["gaz_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Moyenne / Grande puissance</td>
					<td>{{ client.fields["MGP"] || 0 }}</td>
					<td>{{ client.fields["mgp_objectif"] || 0 }}</td>
				</tr>
				<tr class="title">
					<td colspan="3">Energies renouvelables</td>
				</tr>
				<tr>
					<td>Installation solaire thermiques</td>
					<td>{{ client.fields["Solaire"] || 0 }}</td>
					<td>{{ client.fields["solaire_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Installation solaire PV</td>
					<td>{{ client.fields["PV"] || 0 }}</td>
					<td>{{ client.fields["pv_objectif"] || 0 }}</td>
				</tr>
				<tr>
					<td>Chaudières Bois</td>
					<td>{{ client.fields["Ch.Bois"] || 0 }}</td>
					<td>{{ client.fields["bois_objectif"] || 0 }}</td>
				</tr>
				<!-- <tr class="title">
					<td colspan="3">VITOSET</td>
				</tr>
				<tr>
					<td>Chiffres d'affaires</td>
					<td>{{ client.fields["CA Vitoset"] || 0 | currency }} <sup>H.T</sup></td>
					<td>{{ client.fields["vitoset_objectif"] || 0 | currency }}<sup>H.T</sup></td>
				</tr> -->
			</tbody>
			<tfoot>
				<tr>
					<td></td>
					<td>
						Chiffre d'affaires VIESSMANN 2023 <br /><b>{{ client.fields["Chiffres d'affaires réalisés"] || 0 | currency }} <sup>H.T</sup></b>
					</td>
					<td>
						Objectif convenu VIESSMANN 2024 <br /><b>{{ client.fields["Chiffre affaires objectif convenu"] | currency }} <sup>H.T</sup></b>
					</td>
				</tr>
			</tfoot>
		</table>
		<Pieddepage :page="2" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page3",
	components: { Pieddepage },
	data() {
		return {
			pac: this.$store.state.current_client.fields.pac_objectif || 0,
			clim: this.$store.state.current_client.fields.clim_objectif || 0,
			thermodynamique: this.$store.state.current_client.fields.thermodynamique_objectif || 0,
			murales: this.$store.state.current_client.fields.murales_objectif || 0,
			fioul: this.$store.state.current_client.fields.fioul_objectif || 0,
			gaz: this.$store.state.current_client.fields.gaz_objectif || 0,
			mgp: this.$store.state.current_client.fields.mgp_objectif || 0,
			solaire: this.$store.state.current_client.fields.solaire_objectif || 0,
			pv: this.$store.state.current_client.fields.pv_objectif || 0,
			bois: this.$store.state.current_client.fields.bois_objectif || 0,
			vitoset: this.$store.state.current_client.fields.vitoset_objectif || 0,
			total: this.$store.state.current_client.fields["Chiffre affaires objectif théorique"] || 0,
			convenu: this.$store.state.current_client.fields["Chiffre affaires objectif convenu"] || 0
		};
	},
	computed: {
		client() {
			return this.$store.state.current_client;
		}
	},
	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page3 {
	table {
		width: 100%;
		tfoot {
			tr {
				td {
					border-top: solid 2px $black;
					padding: 10px;
					text-align: right;
					font-size: 16px;
					line-height: 20px;
					b {
						font-size: 24px;
						line-height: 30px;
						sup {
							font-size: 10px;
							font-weight: normal;
						}
					}
					&:nth-child(2) {
						border-left: solid 2px black;
					}
					&:nth-child(3) {
						border-left: solid 2px black;
					}
				}
				&:last-child {
					td {
						border-bottom: solid 2px $black;
					}
				}
			}
		}
		thead {
			tr {
				th {
					background: rgba(black, 0.05);
					padding: 10px;
					text-align: left;
					border-top: solid 2px black;
					border-bottom: none;
					border-left: none;
					border-right: none;
					&:nth-child(2) {
						border-left: solid 2px black;
					}
					&:nth-child(3) {
						border-left: solid 2px black;
					}
				}
			}
		}
		tbody {
			tr {
				&.title {
					td {
						border-top: solid 2px black;
						border-bottom: solid 2px black;
						padding: 10px;
						font-weight: bold;
					}
				}
				td {
					padding: 10px 20px;
					border-bottom: solid 1px rgba(black, 0.05);
					&:nth-child(2) {
						border-left: solid 2px black;
						text-align: right;
					}
					&:nth-child(3) {
						border-left: solid 2px black;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}
	}
}
</style>

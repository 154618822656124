<template>
	<div id="secteur-selector" v-if="user && user.fields.role">
		<div v-if="user.fields.role === 'atc'">
			{{ user.fields.secteur }}
		</div>
		<div v-else>
			<select name="secteur_select" id="secteur_select" v-model="secteur" @change="getInfos()">
				<option value="" disabled>Choisissez...</option>
				<option v-for="item in secteurs" :key="item.id" :value="item.fields.secteur.replace(/\s/g, '')"
					>{{ item.fields.secteur }} - {{ item.fields.prenom }} {{ item.fields.nom }}</option
				>
			</select>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: "",
	components: {},
	data() {
		return {
			secteur: ""
		};
	},
	computed: {
		...mapGetters({
			secteurs: "getSecteurs",
			user: "getUser"
		})
	},
	mounted() {
		if (this.user && this.user.fields.role != "atc") {
			this.getSecteurs();
		}
	},
	methods: {
		getSecteurs() {
			this.$store.dispatch("getSecteurs").then(res => {
				if (this.$store.state.current_secteur_client.length > 0 && this.$router.currentRoute.path === "/") {
					this.secteur = this.$store.state.current_secteur_client;
				} else if (this.$store.state.current_secteur_client.length > 0 && this.$router.currentRoute.path === "/statistiques") {
					this.secteur = this.$store.state.current_secteur_stats;
				}
			});
		},
		getInfos() {
			if (this.$router.currentRoute.path === "/statistiques") {
				this.$store.commit("setSecteurStatistiques", this.secteur);
				this.$store.dispatch("getClientsForStatistiques");
			} else if (this.$router.currentRoute.path === "/") {
				this.$store.commit("setSecteurClient", this.secteur);
				this.$store.dispatch("getClients", {
					force: true
				});
			} else if (this.$router.currentRoute.path === "/suspects") {
				this.$store.commit("setSecteurClient", this.secteur);
				this.$store.dispatch("getSuspects", {
					force: true
				});
			}
		}
	}
};
</script>

<style lang="scss">
#secteur-selector {
	#secteur_select {
		max-width: 180px;
		background: transparent;
		border: none;
		padding: 3px 0;
		outline: none;
		margin-left: -5px;
		font-size: 13px;
		font-weight: 500;
		@media screen and (max-width: $screen-phone) {
			max-width: 200px;
		}
	}
}
</style>

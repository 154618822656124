<template>
	<div class="box">
		<div class="part">
			<h2>Coordonnées</h2>
			<div class="data">
				<label>Numéro client</label>
				<span>{{ client.fields["Numéro client"] }}</span>
			</div>
			<div class="data">
				<label>Secteur commercial</label>
				<span>{{ client.fields["Secteur commercial"] }}</span>
			</div>
			<div class="data">
				<label>Société</label> <span>{{ client.fields["Société"] }}</span>
			</div>
			<div class="data">
				<label>Adresse</label> 
				<input type="text" v-model="adresse" @change="handleChange($event, 'Adresse')" />
			</div>
			<div class="data">
				<label>Complément d'adresse</label>
				<input type="text" v-model="complement_adresse" @change="handleChange($event, 'Complément d\'adresse')" />
			</div>
			<div class="data">
				<label>Code postal</label>
				<input type="text" maxlength="6" v-model="code_postal" @change="handleChange($event, 'Code Postal')" />
			</div>
			<div class="data">
				<label>Ville</label>
				<input type="text" maxlength="32" v-model="ville" @change="handleChange($event, 'Ville')" />
			</div>
			<div class="data">
				<label>Téléphone fixe</label>
				<input type="tel" maxlength="15" v-model="telephone_fixe" @change="handleChange($event, 'Téléphone fixe')" />
			</div>
			<div class="data">
				<label>Téléphone portable</label>
				<input type="tel" maxlength="15" v-model="telephone_portable" @change="handleChange($event, 'Téléphone portable')" />
			</div>
			<div class="data">
				<label>Fax</label> 
				<input type="tel" maxlength="15" v-model="fax" @change="handleChange($event, 'Fax')" />
			</div>
			<div class="data">
				<label>Email</label> 
				<input type="email" v-model="email" @change="handleChange($event, 'Email')" />
			</div>
			<div class="data">
				<label>SIRET</label>
				<span>{{ client.fields["SIRET"] }}</span>
			</div>
			<div class="data">
				<label>N° de TVA</label> 
				<span>{{ client.fields["N°de TVA"] }}</span>
			</div>
			<div class="data">
				<label>Site Internet</label>
				<input type="text" v-model="site_internet" @change="handleChange($event, 'Site internet')" />
			</div>
			<div class="data">
				<label>{{ client.fields["Société"] }} dispose d'un compte Viessmann Account</label>
				<van-switch active-color="#558b2f" size="20" v-model="viessmann_account" @change="handleChange($event, 'viessmann_account')" />
			</div>
			<p>
				Pour inscrire votre client à Viessmann account suivez le lien suivant :
				<a href="https://account.viessmann.com/register" target="_blank" nofollow>https://account.viessmann.com/register</a>
			</p>
		</div>

		<div class="part">
			<h2>Interlocuteur principal</h2>
			<div class="data">
				<label>Nom</label> 
				<input type="text" v-model="nom_interlocuteur" @change="handleChange($event, 'Nom interlocuteur principal')" />
			</div>
			<div class="data">
				<label>Prénom</label>
				<input type="text" v-model="prenom_interlocuteur" @change="handleChange($event, 'Prénom interlocuteur principal')" />
			</div>
			<div class="data">
				<label>Téléphone</label>
				<input type="tel" v-model="telephone_interlocuteur" @change="handleChange($event, 'Téléphone interlocuteur principal')" />
			</div>
			<div class="data">
				<label>Email</label>
				<input type="email" v-model="email_interlocuteur" @change="handleChange($event, 'Email interlocuteur principal')" />
			</div>
		</div>

		<p>
			Ces informations sont incorrectes ?
			<a href="https://login.salesforce.com/?locale=fr" target="_blank"><br />Vous pouvez les modifier dans Salesforce pour la partie contact</a>
			et
			<a href="mailto:fr_0600_ADV@viessmann.com">en envoyant un email à fr_0600_ADV pour la partie client.</a>
		</p>
	</div>
</template>
<script>
export default {
	name: "Coordonnées",
	components: {},
	computed: {
		client() {
			return this.$store.state.current_client;
		}
	},
	data() {
		return {
			adresse: this.$store.state.current_client.fields["Adresse"] || "",
			complement_adresse: this.$store.state.current_client.fields["Complément d'adresse"] || "",
			code_postal: this.$store.state.current_client.fields["Code Postal"] || "",
			ville: this.$store.state.current_client.fields["Ville"] || "",
			telephone_fixe: this.$store.state.current_client.fields["Téléphone fixe"] || "",
			telephone_portable: this.$store.state.current_client.fields["Téléphone portable"] || "",
			fax: this.$store.state.current_client.fields["Fax"] || "",
			email: this.$store.state.current_client.fields["Email"] || "",
			siret: this.$store.state.current_client.fields["SIRET"] || "",
			tva: this.$store.state.current_client.fields["N°de TVA"] || "",
			site_internet: this.$store.state.current_client.fields["Site internet"] || "",
			viessmann_account: this.$store.state.current_client.fields.viessmann_account || false,
			nom_interlocuteur: this.$store.state.current_client.fields["Nom interlocuteur principal"] || "",
			prenom_interlocuteur: this.$store.state.current_client.fields["Prénom interlocuteur principal"] || "",
			telephone_interlocuteur: this.$store.state.current_client.fields["Téléphone interlocuteur principal"] || "",
			email_interlocuteur: this.$store.state.current_client.fields["Email interlocuteur principal"] || "",
		};
	},
	methods: {
		handleChange(e, field) {
			if (e.target) {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e.target.value
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e
				});
			}
		}
	}
};
</script>

<style lang="scss">
#clients {
	.box {
		padding: 30px 30px max(120px, env(safe-area-inset-bottom)) 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
		p {
			font-size: 12px;
			margin-bottom: 40px;
			a {
				color: darken($fond, 30%);
			}
		}
		.part {
			flex: 1;
			max-width: 800px;
			margin-bottom: 30px;
			background: white;
			padding: 30px;
			border-radius: 30px;
			box-shadow: 0px 6px 40px rgba($fond, 0.2);
			&.zonetexte {
				font-size: 12px;
				line-height: 20px;
				color: darken($fond, 20%);
				p {
					margin-bottom: 20px;
					font-size: 12px;
				}
				a {
					color: darken($fond, 30%);
				}
				ul {
					li {
						position: relative;
						padding-left: 15px;
						margin-bottom: 4px;
						&:before {
							content: "-";
							border-radius: 8px;
							display: block;
							left: 0;
							position: absolute;
						}
					}
				}
			}
			&.confirmation,
			&.attente {
				text-align: center;
				margin: 50px auto;
				i,
				svg {
					color: $fond;
					font-size: 80px;
					margin-bottom: 20px;
				}
				h2 {
					font-size: 16px;
					line-height: 24px;
				}
				.van-cell {
					flex-direction: column;
					&__title {
						font-size: 13px !important;
						margin-bottom: 10px;
					}
				}
			}
		}
		h2 {
			line-height: 20px;
			margin-bottom: 50px;
			font-size: 22px;
			font-weight: 300;
			color: darken($fond, 10%);
		}
		h3 {
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 0.03em;
			line-height: 20px;
			color: darken($fond, 10%);
			text-transform: uppercase;
			margin: 40px 0 20px 10px;
		}
		.sep {
			height: 20px;
		}

		.qualification {
			display: flex;
			align-items: flex-start;
			padding: 10px 0;
			border-top: solid 1px rgba(black, 0.05);
			.qualif {
				flex: 0 0 auto;
				padding-right: 20px;
				img {
					max-width: 100px;
					width: 100%;
					height: auto;
				}
			}
			.title {
				flex: 0 0 auto;
				width: 120px;
				padding-right: 20px;
				font-weight: bold;
				font-size: 12px;
				text-align: right;
				padding-top: 10px;
			}
			.droite {
				flex: 1;
				padding-top: 15px;
			}
		}
		button,
		button[type="submit"] {
			background: $bleu;
			color: white;
			padding: 6px 10px;
			border-radius: 4px;
			border: none;
			outline: none;
			font-size: 13px;
			cursor: pointer;
			transition: all 0.3s ease;
			&:hover {
				background: $bleufonce;
			}
		}
		.data {
			display: block;
			color: $black;
			font-size: 12px;
			position: relative;
			padding: 6px 10px;
			display: flex;
			align-items: center;
			min-height: 40px;
			border: solid 1px rgba(black, 0.1);
			border-radius: 4px;
			margin-bottom: 20px;
			.van-checkbox,
			.van-radio {
				padding: 5px;
				&__label {
					font-size: 12px;
				}
			}
			input,
			select {
				background: rgba($fond, 0.1);
				border-radius: 4px;
				outline: none;
				border: none;
				padding: 0 10px;
				text-align: right;
				min-height: 42px;
				margin-top: -6px;
				margin-bottom: -6px;
				margin-left: -10px;
				margin-right: -10px;
				width: calc(100% + 20px);
			}
			span {
				display: block;
			}
			label {
				font-size: 11px;
				line-height: 13px;
				color: #797979;
				flex: 0 0 60px;
				position: absolute;
				top: 0;
				left: 8px;
				transform: translateY(-50%);
				padding: 0 2px;
				background: rgba(white, 0.1);
				backdrop-filter: blur(10px);
			}
			&.right {
				text-align: right;
				justify-content: flex-end;
				padding-right: 20px;
			}
			&.total {
				color: black;
				label {
					color: black;
				}
			}
		}
	}
}
</style>

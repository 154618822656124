<template>
	<div class="pageprint" id="page1">
		<h1>Informations de l'entreprise</h1>

		<h2>Coordonnées</h2>
		<div class="data">
			<label>Numéro client</label>
			<span>{{ client.fields["Numéro client"] }}</span>
		</div>
		<div class="data">
			<label>Secteur commercial</label>
			<span>{{ client.fields["Secteur commercial"] }}</span>
		</div>
		<div class="data">
			<label>Société</label> <span>{{ client.fields["Société"] }}</span>
		</div>
		<div class="data">
			<label>Adresse</label> <span>{{ client.fields["Adresse"] }}</span>
		</div>
		<div class="data">
			<label>Complément d'adresse</label>
			<span>{{ client.fields["Complément d'adresse"] }}</span>
		</div>
		<div class="data">
			<label>Code postal et ville</label>
			<span>{{ client.fields["Code Postal"] }} {{ client.fields["Ville"] }}</span>
		</div>
		<div class="data">
			<label>Téléphone fixe</label>
			<span>{{ client.fields["Téléphone fixe"] }}</span>
		</div>
		<div class="data">
			<label>Téléphone portable</label>
			<span>{{ client.fields["Téléphone portable"] }}</span>
		</div>
		<div class="data">
			<label>Fax</label> <span>{{ client.fields["Fax"] }}</span>
		</div>
		<div class="data">
			<label>Email</label> <span>{{ client.fields["Email"] }}</span>
		</div>
		<div class="data">
			<label>SIRET</label> <span>{{ client.fields["SIRET"] }}</span>
		</div>
		<div class="data">
			<label>N° de TVA</label> <span>{{ client.fields["N°de TVA"] }}</span>
		</div>
		<div class="data">
			<label>Site Internet</label>
			<span>{{ client.fields["Site internet"] }}</span>
		</div>
		<div class="data">
			<label>{{ client.fields["Société"] }} dispose d'un compte Viessmann Account</label>
			<span v-if="client.fields.viessmann_account">Oui</span>
			<span v-else>Non</span>
		</div>

		<div class="sep"></div>
		<h2>Interlocuteur principal</h2>
		<div class="data"><label>Nom</label> {{ client.fields["Nom interlocuteur principal"] }}</div>
		<div class="data">
			<label>Prénom</label>
			{{ client.fields["Prénom interlocuteur principal"] }}
		</div>
		<div class="data">
			<label>Téléphone</label>
			{{ client.fields["Téléphone interlocuteur principal"] }}
		</div>
		<div class="data"><label>Email</label> {{ client.fields["Email interlocuteur principal"] }}</div>

		<div class="sep"></div>
		<h1>Plan d'action commercial</h1>
		<h2>Classification du client</h2>
		<div class="data">
			<label>Branche</label>
			{{ client.fields["Branche"] }}
		</div>
		<div class="data">
			<label>Classe de partenaire (si PAC Signé)</label>
			{{ classe }}
		</div>
		<div class="data">
			<label>Visites réalisées l'année précédente</label>
			{{ client.fields["Visites réalisées"] }}
		</div>
		<div class="data">
			<label>Objectif de visites</label>
			{{ objectifs_visites }}
		</div>
		<div class="data">
			<label>Potentiel du client</label>
			{{ client.fields["Potentiel du client"] }}
		</div>
		<!-- <div class="data">
			<label>Potentiel en PAC</label>
			{{ client.fields["Potentiel en PAC"] }}
		</div>
		<div class="data">
			<label>Potentiel en CLIM</label>
			{{ client.fields["Potentiel en CLIM"] }}
		</div> -->
		<div class="data" v-if="client.fields['Installateur du neuf']">
			<label>Installateur du neuf</label>
			Oui
		</div>
		<div class="data" v-if="client.fields['154 Non primable']">
			<label>154 Non primable</label>
			Oui
		</div>
		<!-- Ne faire apparaitre que pour les basic !-->
		<div class="data" v-if="client.fields['Cibler en prospect']">
			<label>Ciblage en prospect</label>
			Oui
		</div>

		<!-- Ne faire apparaitre que pour les silver !-->
		<!-- <div class="data" v-if="client.fields['Wild Card Proactif']">
			<label>Utilisation d'une WildCard Proactif</label>
			Oui
		</div> -->
		<h3>Crédit Management</h3>
		<div class="data">
			<label>Plafond de crédit actuel</label>
			{{ client.fields["Plafond de crédit actuel"] | currency }}
		</div>
		<Pieddepage :page="1" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page1",
	components: { Pieddepage },
	data() {
		return {
			classe: this.$store.state.current_client.fields["Classe de partenaire"],
			cibler_prospect: this.$store.state.current_client.fields["Cibler en prospect"],
			plafond_souhaite: this.$store.state.current_client.fields["Plafond de crédit souhaité"],
			wild_card: this.$store.state.user.fields.wild_card,
			use_wild_card: this.$store.state.current_client.fields["Wild Card Proactif"]
		};
	},
	computed: {
		client() {
			return this.$store.state.current_client;
		},
		objectifs_visites() {
			let temp = 0;
			if (this.classe == "Basic (PAR)") {
				temp = 0;
			} else if (this.classe == "Partenaire (MAP)") {
				temp = 8;
			} else if (this.classe == "Prospect (EK)") {
				temp = 24;
			} else if (this.classe == "Proactif (MIP)") {
				temp = 12;
			} else if (this.classe == "Proactif ÉcoSystème (MI+)") {
				temp = 16;
			}
			return temp;
		}
	},
	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
</style>

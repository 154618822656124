<template>
	<div class="pageprint" id="page7">
		<h2>Conditions générales de vente 2024</h2>

		<div class="deux-colonnes">
			<p>3.6. Emballages</p>

			<p>
				En l&rsquo;absence d&rsquo;indications spéciales concernant les emballages, ceux-ci sont réalisés par le Vendeur selon les usages en vigueur pour le type de
				matériel concerné. Les emballages ne sont pas repris par le Vendeur, sauf accord exprès de celui-ci. Toute réalisation d&rsquo;emballage spécifique à la
				demande de l&rsquo;Acheteur lui sera facturée séparément.
			</p>
			<p>
				3.7. Délais de livraison<br />
				Les délais de livraison courent à partir de la plus tardive des dates suivantes : - date de l&rsquo;accusé de réception de commande<br />
				- date de réception de tous renseignements et documents dus par l&rsquo;Acheteur<br />
				- date d&rsquo;exécution de toutes les obligations contractuelles ou légales préalables de l&rsquo;Acheteur, notamment le paiement d&rsquo;un acompte que
				l&rsquo;Acheteur s&rsquo;était engagé à remettre.
			</p>

			<p>
				Les délais sont donnés à titre indicatif. Les retards ne peuvent en aucun cas donner lieu, au profit de l&rsquo;Acheteur, à des pénalités de retard, à des
				indemnités à quelque titre que ce soit, ou à l&rsquo;annulation de la commande.
			</p>

			<p>
				En cas de retard de paiement ou de litige financier au sens de l&rsquo;article 6.1 des présentes, le Vendeur est en droit de suspendre toute livraison ou
				autre engagement contractuel.
			</p>

			<p>3.8. Prix applicable, facturation minimale, catalogue</p>

			<p>Les prix du Vendeur s&rsquo;entendent hors taxes, emballage et frais de transports compris et hors prestations de services.</p>

			<p>
				Le Vendeur s&rsquo;accorde le droit de modifier ses tarifs à tout moment. Toutefois il s&rsquo;engage à facturer le Matériel aux prix indiqués dans le Tarif
				en vigueur au jour de l&rsquo;expédition, sauf accord préalable sur un prix et/ou un délai déterminé(s) pour un maximum de 3 mois pour toute livraison de
				Matériel catalogué. Pour toute commande inférieure à 100 euros Hors Taxes, un supplément pour participation forfaitaire aux frais de traitement de la
				commande de 15 euros Hors Taxes non remboursable sera appliqué.
			</p>

			<p>3.9. Livraison sous 24 heures pour les pièces de rechange</p>

			<p>
				Une livraison sous 24 heures de pièces de rechange demandée par l&rsquo;Acheteur est possible sous certaines conditions, qui seront validées lors de la
				commande, moyennant une facturation forfaitaire de 6 euros Hors Taxes correspondant aux coûts de traitement et d&rsquo;expédition liés.
			</p>

			<p><strong>IV. Force majeure</strong></p>

			<p>4.1. Cas de force majeure</p>

			<p>
				Les cas de force majeure de toute nature, les perturbations imprévisibles au niveau de l&rsquo;exploitation, du transport et de l&rsquo;expédition, les
				faits de guerre, les actes de terrorisme, les incendies, les catastrophes naturelles et les inondations, la pénurie imprévisible de
				main-d&rsquo;&oelig;uvre, d&rsquo;énergie, de matières premières ou de produits auxiliaires, les cas de grève ou de lock-out, les mesures prises par les
				pouvoirs publics et tout autre obstacle ne relevant pas de la volonté des Parties et ralentissant, retardant, empêchant ou rendant déraisonnables la
				fabrication, l&rsquo;expédition, la réception ou l&rsquo;utilisation du Matériel, délient les Parties de leurs obligations de livraison et de réception
				pendant la durée de la perturbation et compte tenu de son incidence.
			</p>

			<p>4.2. Résiliation du contrat</p>

			<p>
				Dans la mesure où la date de livraison et/ou de réception se trouve dépassée de plus de 12 semaines du fait d&rsquo;un événement de force majeure, chaque
				Partie a le droit de prononcer la résiliation de la commande par lettre recommandée avec accusé de réception. En cas de défaillance totale ou partielle des
				sources d&rsquo;approvisionnement du Vendeur, celui-ci essayera de s&rsquo;approvisionner auprès d&rsquo;un tiers. Si cela s&rsquo;avère impossible ou
				serait irraisonnable en raison des surcoûts, le Vendeur pourra également annuler de plein droit la commande.
			</p>

			<p><strong>V. Retours hors cas de garantie</strong></p>

			<p>5.1. Retour de Matériels conformes</p>

			<p>
				Un retour, à savoir la reprise de Matériels conformes par le Vendeur et la constatation d&rsquo;un avoir au profit de l&rsquo;Acheteur, ne peut être
				effectué qu&rsquo;avec l&rsquo;accord écrit du Vendeur. Le fait pour le Vendeur d&rsquo;avoir consenti à un retour pour un certain Matériel, ne confère pas
				à l&rsquo;Acheteur le droit d&rsquo;obtenir un retour pour d&rsquo;autres Matériels, même identiques ou similaires. Dans le cas où le Vendeur a consenti au
				retour, celui-ci devra notamment répondre aux conditions suivantes :
			</p>

			<p>
				- le Matériel d&rsquo;une valeur inférieure à 50 euros net HT ainsi que les Matériels de fumisterie ne font pas l&rsquo;objet d&rsquo;un avoir ni d&rsquo;un
				retour. Le Matériel doit être retourné au plus tard trois mois après la date de livraison
			</p>

			<p>
				- le retour est fait au lieu indiqué par le Vendeur selon les modalités fixées par le Vendeur. Les frais du retour seront déduits de l&rsquo;avoir établi
				par le Vendeur. Le Matériel devra être retourné en parfait état, protégé et emballé dans son emballage d&rsquo;origine non ouvert, à défaut de quoi le
				Vendeur se réserve le droit de débiter l&rsquo;Acheteur des frais de remise en état ou de refuser le Matériel qui ne serait plus utilisable
			</p>

			<p>
				- le retour donne lieu, si éligible, à l&rsquo;établissement d&rsquo;un avoir correspondant au prix des Matériels concernés, moins une retenue forfaitaire
				au titre du traitement administratif du retour, égale à 5 % du prix net des Matériels facturés avec un minimum de 50 &euro; HT
			</p>

			<p>- le retour doit être effectué dans les 30 jours à dater de l&rsquo;accord du Vendeur</p>

			<p>- tout Matériel retourné en violation des conditions précédentes sera systématiquement retourné à son expéditeur et à ses frais</p>

			<p>5.2. Retours non conformes</p>

			<p>
				Dans l&rsquo;hypothèse où le Matériel reçu par l&rsquo;Acheteur ne correspond pas au Matériel commandé (référence incorrecte, fonctions essentielles
				indiquées dans la fiche article manquantes, etc.), la reprise du Matériel aux frais du Vendeur et l&rsquo;établissement d&rsquo;un avoir ne peut être
				effectué, qu&rsquo;après une réclamation conformément à l&rsquo;article 3.5 des présentes et l&rsquo;obtention de l&rsquo;accord écrit du Vendeur. Le retour
				devra être effectué dans un délai maximum de 7 jours calendaires à dater de l&rsquo;accord du Vendeur. Tout Matériel retourné sans l&rsquo;application des
				conditions précédentes sera systématiquement renvoyé à son expéditeur et à ses frais.
			</p>

			<p><strong>VI. Conditions de paiement</strong></p>

			<p>6.1. Délais et retards de paiement</p>

			<p>
				Sauf convention contraire entre les Parties, toutes les factures du Vendeur sont payables dans un délai de 30 jours à compter de la date de facturation par
				virement bancaire sur le compte indiqué dans la facture. Seul l&rsquo;encaissement effectif sur le compte du Vendeur est considéré comme un paiement. Les
				paiements ne peuvent être retardés sous quelque prétexte que ce soit, y compris en cas de litige. L&rsquo;Acheteur ne peut faire valoir au titre de
				compensation que des créances non contestées par le Vendeur ou dont le caractère exécutoire a été établi par une décision de justice passée en force de
				chose jugée.
			</p>

			<p>
				Conformément à l&rsquo;article L441-6 du Code de commerce, tout retard de paiement donnera lieu à l&rsquo;application, à compter du premier jour de retard,
				de plein droit et sans qu&rsquo;une mise en demeure préalable soit nécessaire, de pénalités de retard égales à trois fois le taux d&rsquo;intérêts légal. En
				outre, tout retard de paiement donnera lieu au paiement d&rsquo;une indemnité forfaitaire pour frais de recouvrement d&rsquo;un montant de 40 euros. Lorsque
				les frais de recouvrement exposés sont supérieurs au montant de ladite indemnité forfaitaire, le Vendeur pourra demander une indemnisation complémentaire,
				sur justificatif.
			</p>

			<p>6.2. Escompte</p>

			<p>
				Sur le paiement des factures le Vendeur accorde à l&rsquo;Acheteur un escompte à hauteur de 0,08 % sur le montant des factures pour chaque semaine
				d&rsquo;anticipation.
			</p>

			<p>6.3. Avoirs</p>

			<p>
				En cas d&rsquo;erreur de facturation en défaveur de l&rsquo;Acheteur, le Vendeur établit un avoir au profit de celui-ci dans un délai de 2 mois à compter de
				la réception de l&rsquo;information de l&rsquo;erreur. Cet avoir sera repris a maxima dans les relevés de facturation suivant l&rsquo;issu du délai de 2
				mois. Si la note de crédit est consécutive à un litige technique réclamant une expertise approfondie, le montant égal à la valeur du litige sera défalqué de
				la prochaine facture à intervenir après l&rsquo;extinction du délai de deux mois. Une régularisation sera alors effectuée à réception de la note
				d&rsquo;expertise par un avoir ou la production d&rsquo;une facture. Le remboursement pourra être demandé par l&rsquo;acheteur si aucune commande
				n&rsquo;intervient dans le délai de 365 jours suivant son émission.
			</p>

			<p>6.4. Créances ouvertes</p>

			<p>
				Le Vendeur se réserve le droit d&rsquo;affecter les sommes versées par l&rsquo;Acheteur à l&rsquo;apurement des créances les plus anciennes, majorées des
				intérêts de retard et des frais dans l&rsquo;ordre suivant: frais, intérêts, principal.
			</p>

			<p><strong>VII. Transfert des risques et Réserve de propriété ; Revente de Matériel</strong></p>

			<p>7.1. Transfert des risques</p>

			<p>
				Le transfert des risques de perte et de détérioration du Matériel s&rsquo;opère au moment de livraison. Si l&rsquo;Acheteur est en retard dans la réception
				du Matériel, le transfert des risques s&rsquo;opérera à la date à laquelle la réception aurait dû avoir lieu.
			</p>

			<p>7.2. Transfert et réserve de propriété</p>

			<p>
				Le Vendeur conserve la propriété des Matériels vendus identifiés par sa désignation et/ ou son numéro de série, jusqu&rsquo;au paiement effectif de
				l&rsquo;intégralité du prix en principal et accessoires. Le défaut de paiement de l&rsquo;une quelconque des échéances pourra entraîner la revendication de
				ces Matériels, qui doivent pouvoir être identifiés dans les stocks de l&rsquo;Acheteur. L&rsquo;Acheteur assume néanmoins, à compter de la livraison, les
				risques de perte ou de détérioration de ces Matériels ainsi que la responsabilité des dommages qu&rsquo;ils pourraient occasionner.
			</p>

			<p>
				L&rsquo;Acheteur s&rsquo;engage à garder soigneusement les Matériels faisant l&rsquo;objet de la réserve de propriété pour le compte du Vendeur, à les
				maintenir et à les remettre en état à ses frais, et à les assurer à ses frais contre les risques de perte et de détérioration dans le cadre requis pour une
				personne ayant qualité de commerçant.
			</p>

			<p>
				Il cède d&rsquo;ores et déjà au Vendeur ses droits résultants des contrats d&rsquo;assurance. Il s&rsquo;engage à informer immédiatement le Vendeur de toute
				menace, saisie, action, réquisition, confiscation ou toute autre mesure par des tiers pouvant mettre en cause le droit de propriété du Vendeur.
			</p>
			<p>
				Le Vendeur a le droit, sans mise en demeure et sans résiliation du contrat, d&rsquo;exiger de l&rsquo;Acheteur la restitution du Matériel faisant
				l&rsquo;objet de la réserve de propriété, si ledit Acheteur ne s&rsquo;acquitte pas régulièrement de ses obligations à l&rsquo;égard du Vendeur. La reprise
				du Matériel faisant l&rsquo;objet de la réserve de propriété ne peut être considérée comme une résiliation du contrat que si le Vendeur en fait la
				déclaration expresse par écrit. Si le Vendeur décide de résilier le contrat, il est en droit d&rsquo;exiger de l&rsquo;Acheteur une indemnité adéquate pour
				la durée de la mise à disposition du Matériel.
			</p>
			<p>7.3. Revente du Matériel</p>

			<p>
				L&rsquo;Acheteur a le droit de revendre le Matériel à des professionnels ou des particuliers en points de vente physiques, sur internet (&ldquo;vente en
				ligne&ldquo;), ou par tout autre moyen utile, uniquement dans sa zone de chalandise.
			</p>
			<p>
				Dans l&rsquo;exercice de ce droit de revente du Matériel, l&rsquo;Acheteur s&rsquo;engage auprès du Vendeur à respecter les principes essentiels du droit de
				la concurrence et notamment
			</p>

			<p>
				- L&rsquo;interdiction des pratiques restrictives de concurrence telles que la vente à perte (article L. 442-2 Code de commerce) sous peine d&rsquo;être
				condamné à 75 000 &euro; d&rsquo;amende
			</p>
			<p>
				- L&rsquo;interdiction d&rsquo;avoir recours à des pratiques commerciales trompeuses telles que définies aux articles L. 121-1 et suivants du code de la
				consommation sous peine d&rsquo;être condamné à 2 ans d&rsquo;emprisonnement et 300 000 &euro; d&rsquo;amende ainsi qu&rsquo;interdiction d&rsquo;exercer
				une profession commerciale ou industrielle, de diriger, d&rsquo;administrer, de gérer ou de contrôler à un titre quelconque, directement ou indirectement,
				pour leur propre compte ou pour le compte d&rsquo;autrui, une entreprise commerciale ou industrielle ou une société commerciale, pour une durée de cinq ans
				au plus.
			</p>
		</div>

		<Pieddepage :page="5" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page7",
	components: { Pieddepage },
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page7 {
	.data {
		address {
			text-align: right;
		}
	}
	.deux-colonnes {
		margin-top: 40px;
		column-count: 2;
		column-gap: 100px;
		font-size: 16px;
		line-height: 21px;
		text-align: justify;
		strong {
			margin-top: 10px;
			display: block;
		}
	}
}
</style>

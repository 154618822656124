<template>
	<div id="client">
		<div class="process" v-if="!$store.state.generator_mode">
			<van-steps :active="active" :direction="direction" active-icon="stop" active-color="#03a9f4" @click-step="handleStep($event)" v-if="!ferme">
				<van-step>Coordonnées</van-step>
				<van-step>Plan d'action commercial</van-step>
				<van-step>Objectif CA</van-step>
				<!-- <van-step>Qualitications RGE</van-step> -->
				<!-- <van-step>Services</van-step> -->
				<!-- <van-step>Aides à la vente</van-step> -->
				<van-step v-show="!client.fields['Classe de partenaire'].includes('Prospect') && !client.fields['Classe de partenaire'].includes('Basic')"
					>Charte de partenariat</van-step
				>
				<van-step>Documentation</van-step>
				<van-step>Signature</van-step>
			</van-steps>
			<div class="droite">
				<Coordonnees v-if="active == 0" />
				<PAC v-if="active == 1" />
				<Volumes v-if="active == 2" />
				<!-- <Qualifications v-if="active == 3" /> -->
				<!-- <Formation v-if="active == 4" /> -->
				<!-- <Services v-if="active == 3" /> -->
				<!-- <Aides v-if="active == 4" /> -->
				<Chartes
					v-show="!client.fields['Classe de partenaire'].includes('Prospect') && !client.fields['Classe de partenaire'].includes('Basic')"
					v-if="active == 3"
				/>
				<!-- <Recompenses v-if="active == 7" v-show="client.fields['Classe de partenaire'].includes('Proactif')" /> -->
				<Documentation v-if="active == 4" />
				<Signature v-if="active == 5" />
			</div>
		</div>
	</div>
</template>
<script>
import Coordonnees from "../components/pac/coordonnees";
import PAC from "../components/pac/pac";
import Volumes from "../components/pac/volumes";
//import Formation from "../components/pac/formation";
//import Qualifications from "../components/pac/qualifications";
//import Services from "../components/pac/services";
//import Aides from "../components/pac/aidealavente";
import Signature from "../components/pac/signature";
import Chartes from "../components/pac/charte";
import Documentation from "../components/pac/documentation";
import moment from "moment";
import { mapMutations } from "vuex";
export default {
	name: "Client",
	components: {
		Coordonnees,
		PAC,
		Volumes,
		Signature,
		Chartes,
		Documentation
	},
	data() {
		return {
			active: 0,
			ferme: false,
			direction: window.screen.width <= 600 ? "horizontal" : "vertical"
		};
	},
	computed: {
		client() {
			return this.$store.state.current_client;
		}
	},
	mounted() {
		let now = moment();
		if (now.isAfter("2024-04-06") && this.$store.state.user.fields.email != "biec@viessmann.com") {
			this.ferme = true;
		}
		this.active = this.$store.state.current_client.fields.last_open_tab || 0;
	},
	methods: {
		handleStep(step) {
			this.active = step;
			this.$store.dispatch("lastOpenStep", {
				id: this.client.id,
				step: step
			});
		}
	}
};
</script>

<style lang="scss">
#client {
	height: 100%;
	@media screen and (max-width: $screen-phone) {
		height: auto;
	}
	.process {
		background: rgba($fond, 0.1);
		position: relative;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		@media screen and (max-width: $screen-phone) {
			display: block;
		}
		.van-steps {
			flex: 0 0 220px;
			padding: 30px 0px 10px 40px;
			background: transparent;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			@media screen and (max-width: $screen-phone) {
				display: flex;
				flex-wrap: nowrap;
				overflow-x: auto;
				overflow-y: hidden;
				-webkit-overflow-scrolling: touch;
				padding: 20px 20px 0 20px;
				.van-step {
					min-width: 110px;
					.van-step__title {
						transform: none;
					}
					&:last-child {
						position: relative;
						.van-step__circle-container {
							left: 0 !important;
						}
					}
				}
			}
			.van-step__title {
				font-size: 11px;
				font-weight: 500;
				color: darken($fond, 20%);
				margin-bottom: 3px;
				padding: 4px 0;
				transform: translateY(16px);
				cursor: pointer;
				&:hover {
					color: $rouge;
				}
			}
			.van-step__circle-container {
				font-size: 24px;
				top: 40px;
				background: transparent;
			}
			.van-step__icon {
				font-size: 18px;
			}
			.van-step__line {
				top: 40px;
				width: 2px;
				background: rgba($fond, 0.1);
				left: -16px;
			}
			.van-step__circle {
				background: transparent !important;
			}
		}
		.droite {
			flex: 3;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
	}
}
</style>

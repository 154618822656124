import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import VueCurrencyFilter from "vue-currency-filter";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import JsonCSV from "vue-json-csv";
import Sticky from "vue-sticky-directive";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const moment = require("moment");
require("moment/locale/fr");

Vue.component("downloadCsv", JsonCSV);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.use(Donut);
Vue.use(Sticky);

Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(require("vue-moment"), { moment });
Vue.use(VueCurrencyFilter, {
	symbol: "€",
	thousandsSeparator: " ",
	fractionCount: 2,
	fractionSeparator: ",",
	symbolPosition: "back",
	symbolSpacing: false,
	avoidEmptyDecimals: undefined
});

Sentry.init({
	Vue,
	dsn: "https://a82bae77c0f6440c93cff1e895d1a36c@o982122.ingest.sentry.io/6050772",
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ["localhost", "pac.viessmann-france.com", /^\//]
		})
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");

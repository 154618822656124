<template>
	<div class="pageprint" id="page8">
		<h2>Conditions générales de vente 2024</h2>

		<div class="deux-colonnes">
			<p>
				Viessmann France se réserve le droit de dénoncer auprès de la DGCCRF les pratiques commerciales trompeuses, ou agressives ainsi que les pratiques portant
				atteinte à la concurrence.
			</p>

			<p>
				Lorsque l&rsquo;Acheteur revend le Matériel en ligne, sur son showroom ou sur un salon professionnel, il doit respecter les exigences de qualité du Vendeur
				concernant la présentation, l&rsquo;apparence et l&rsquo;utilisation des sites internet dédiés à la revente du Matériel. Ces exigences qui servent à
				préserver l&rsquo;image et la réputation du Matériel et qui garantissent la présentation uniforme du Matériel vis-à-vis du Client sont définies dans la
				fiche &ldquo;Charte Viessmann&ldquo; établie et portée à la connaissance de l&rsquo;Acheteur par le Vendeur.
			</p>

			<p>
				Aucun droit, titre ou intérêt relatif ou lié au nom, marques de fabrique, secrets de fabrication, brevets, demandes de brevets, expertises ou autres droits
				de propriété intellectuelle relatifs aux Matériels n&rsquo;est transféré ni par les présentes CGV, ni par la vente à l&rsquo;acheteur.
			</p>

			<p><strong>VIII. Modification et résiliation des relations contractuelles</strong></p>

			<p>8.1. Modification des relations</p>

			<p>
				Toute modification dans les relations avec l&rsquo;Acheteur en raison d&rsquo;une vente, cession, mise en nantissement ou apport en société de son fonds de
				commerce ou de son Matériel par l&rsquo;Acheteur, de changement dans la détention de la majorité de son capital, fusion, scission ou apport partiel
				d&rsquo;actif, nécessite l&rsquo;accord préalable du Vendeur.
			</p>

			<p>8.2. Résiliation</p>

			<p>
				Le contrat pourra être résilié de plein droit par le Vendeur par écrit dans le cas où l&rsquo;Acheteur est en retard de paiement partiel ou complet, malgré
				une mise en demeure notifiée par lettre recommandée avec accusé de réception. L&rsquo;acompte éventuellement perçu par le Vendeur au titre de contrat
				résilié lui restera acquis en toute circonstance et ne sera susceptible d&rsquo;aucune restitution à l&rsquo;Acheteur ou de compensation de la part de ce
				dernier. Toutes les sommes dues, également au titre d&rsquo;autres commandes de l&rsquo;Acheteur, deviendront immédiatement exigibles. En cas de dégradation
				significative de la solvabilité de l&rsquo;Acheteur, le Vendeur est en droit d&rsquo;exiger la mise en place d&rsquo;une garantie qu&rsquo;il aura agréée.
				En cas de refus par l&rsquo;Acheteur, le contrat pourra être résilié de plein droit par le Vendeur après mise en demeure préalable. La résiliation du
				contrat ne porte néanmoins pas atteinte aux créances déjà échues entre les Parties.
			</p>

			<p><strong>IX. Garantie légale</strong></p>

			<p>
				Le Vendeur peut octroyer à l&rsquo;Acheteur une garantie contractuelle, dont les termes feront l&rsquo;objet le cas échéant d&rsquo;un document séparé, sans
				préjudice de l&rsquo;application, le cas échéant, de la garantie légale des vices cachés résultant de l&rsquo;article 1641 du Code Civil.
			</p>

			<p>9.1. Restrictions</p>

			<p>
				La garantie légale ne s&rsquo;applique pas aux conséquences de l&rsquo;usure normale. Par ailleurs, la garantie est notamment exclue dans les cas suivants :
			</p>

			<p>
				- le stockage ou l&rsquo;entreposage du Matériel à un emplacement inadéquat, notamment l&rsquo;exposition à l&rsquo;humidité, à des intempéries ou à des
				impacts chimiques, électriques ou électromagnétiques
			</p>

			<p>
				- la mise en &oelig;uvre et l&rsquo;installation non conforme aux documents du Vendeur (notice d&rsquo;utilisation et de montage) et aux règles de
				l&rsquo;art
			</p>

			<p>
				- la mise en oeuvre et/ou installation non réalisées par un professionnel qualifié de la filière thermique/climatique avec des connaissances approfondies de
				ce domaine
			</p>

			<p>- une qualité de l&rsquo;eau utilisée insuffisante, tant au niveau du circuit de chauffage que du circuit sanitaire</p>

			<p>- l&rsquo;absence d&rsquo;entretien périodique réalisé par un professionnel qualifié</p>

			<p>- les réparations ou remplacements non conformes aux règles de l&rsquo;art et aux prescriptions techniques réalisés par l&rsquo;Acheteur ou des tiers</p>

			<p>- l&rsquo;utilisation non conforme à l&rsquo;usage auquel le Matériel est destiné et aux prescriptions des notices d&rsquo;utilisation</p>

			<p>- pour des vices provenant d&rsquo;une conception réalisée par l&rsquo;Acheteur, même partiellement</p>

			<p>9.2. Durée et point de départ de la garantie</p>

			<p>
				Les droits découlant de la garantie légale se prescrivent 24 mois à compter du jour de la livraison. Les éventuelles réparations et les remplacements
				effectués dans le cadre de la garantie ne font pas courir une nouvelle durée de garantie et ne prolongent pas la garantie initiale.
			</p>

			<p>9.3. Obligations de l&rsquo;Acheteur</p>

			<p>
				L&rsquo;Acheteur informe le Vendeur, sans retard et par écrit, des défauts imputés au Matériel et lui fournit toutes justifications quant à la réalité de
				ceux-ci. Il veillera également à ce que le Vendeur bénéficie de toute facilité pour procéder à la constatation de ces défauts et pour y porter remède le cas
				échéant. Toute réparation effectuée par l&rsquo;Acheteur ou un tiers sans l&rsquo;accord du Vendeur sur le Matériel, même reconnu défectueux, entraîne la
				perte de toute garantie, ainsi que de tout droit à recours contre le Vendeur.
			</p>

			<p>9.4. Mise en &oelig;uvre de la garantie</p>

			<p>
				Dans le cas d&rsquo;un Matériel reconnu défectueux, le Vendeur ne pourra être tenu en toute hypothèse qu&rsquo;à la réparation ou au remplacement pur et
				simple de ce seul Matériel, ceci par la mise en oeuvre de moyens logistiques dont il est seul juge, et sans autre indemnité d&rsquo;aucune sorte.
				L&rsquo;Acheteur aura à sa charge les frais de transport, emballage, montage, démontage et tous frais annexes. Le Vendeur n&rsquo;acceptera aucun retour de
				Matériel sans l&rsquo;avoir préalablement autorisé par écrit.
			</p>

			<p>Les frais de déplacement du personnel et de main d&rsquo;&oelig;uvre sont exclus de la garantie.</p>

			<p>9.5. Dispositions légales impératives</p>

			<p>Ces restrictions ne s&rsquo;appliquent que dans la mesure où des dispositions légales impératives ne sont pas contraires.</p>

			<p>9.6. Résultats industriels ou économiques et responsabilité de l&rsquo;Acheteur</p>

			<p>
				Le Vendeur n&rsquo;est pas responsable pour les résultats industriels ou économiques escomptés. Toute responsabilité résultant du choix du Matériel incombe
				à l&rsquo;Acheteur.
			</p>

			<p>9.7. Durée de disponibilité des pièces de rechange</p>

			<p>
				Il est prévisible que les pièces de rechange nécessaires à l&rsquo;utilisation du Matériel déterminé seront disponibles pendant une durée de cinq années à
				compter de la date de publication du dernier catalogue sur lequel figure le Matériel - le même Matériel étant défini comme celui qui comporte la même
				référence.
			</p>

			<p>9.8. Recyclage et fin de vie des déchets d&rsquo;équipements électriques et électroniques (DEEE)</p>

			<p>
				Conformément aux dispositions du Code de l&rsquo;environnement en matière de Déchets d&rsquo;Equipements Électriques et Électroniques (DEEE), la société
				Viessmann France SAS a adhéré à Ecosystem et à Soren, éco-organismes agréés par les Pouvoirs publics aux conditions définies par l&rsquo;article R543-197.
			</p>

			<p>
				IDU Filière REP DEEE : FR006530_05TJJO. Elle apporte ainsi à ses clients la garantie de pouvoir bénéficier du dispositif de collecte et de recyclage proposé
				par ces organismes pour les DEEE issus des équipements qu&rsquo;elle a mis en marché.
			</p>

			<p>Plus d&rsquo;information rendez-vous sur les sites https://www.ecosystem.eco et https://www.soren.eco.</p>

			<p>
				Conformément à l&rsquo;article L. 541-10-2 du Code de l&rsquo;environnement, les factures émises par le Vendeur feront apparaître les coûts unitaires
				supportés pour l&rsquo;élimination des déchets d&rsquo;équipements électriques et électroniques ménagers. Ces coûts ne pourront pas faire l&rsquo;objet de
				réfaction. L&rsquo;Acheteur répercutera à l&rsquo;identique ces coûts unitaires jusqu&rsquo;au consommateur final.
			</p>

			<p><strong>X. Dommages et intérêts</strong></p>

			<p>
				Une Partie ne peut pas se prévaloir d&rsquo;une inexécution par l&rsquo;autre Partie dans la mesure où cette inexécution est due à un acte ou à une omission
				de sa part ou à un évènement de Force Majeure.
			</p>

			<p>
				La Partie qui invoque une inexécution de l&rsquo;autre Partie doit prendre les mesures raisonnables, eu égard aux circonstances, pour limiter les dommages
				résultant de l&rsquo;inexécution. Si elle néglige de le faire, la Partie en défaut peut demander une réduction des dommages-intérêts égale au montant du
				dommage qui aurait dû être évité.
			</p>

			<p>
				Le Vendeur ne pourra être tenu que de la réparation des conséquences pécuniaires des dommages directs et prévisibles lors de la conclusion du Contrat. A cet
				égard, les Parties conviennent en particulier que tout préjudice d&rsquo;ordre financier ou commercial tel que notamment le gain manqué, la perte
				d&rsquo;exploitation, le préjudice commercial, la perte d&rsquo;une chance, de chiffre d&rsquo;affaires, de bénéfice, d&rsquo;une économie escomptée, la
				perte de clientèle, la détérioration d&rsquo;image, l&rsquo;augmentation des frais généraux constituent un dommage ou préjudice indirect au sens du présent
				Contrat.
			</p>

			<p>
				En tout état de cause la responsabilité cumulée du Vendeur au titre de la commande, toutes causes confondues, ne saurait excéder la valeur totale hors taxes
				de la commande.
			</p>

			<p>Toute action dirigée contre le Vendeur devra être introduite, sous peine de forclusion, au plus tard 12 mois après la constatation de la violation.</p>

			<p>
				Il n&rsquo;est pas dérogé aux dispositions légales d&rsquo;ordre public en matière de responsabilité, par exemple celles qui sont valables en cas de mise en
				jeu d&rsquo;une garantie contractuelle, de faute intentionnelle ou de négligence grave, de dommages corporels ou d&rsquo;atteinte à la vie ou à la santé
				d&rsquo;autrui ou encore celles de la loi sur la responsabilité produit défectueux.
			</p>

			<p><strong>XI. Droit applicable et attribution de juridiction</strong></p>

			<p>11.1. Droit applicable</p>

			<p>Les présentes conditions générales, l&rsquo;ensemble des contrats qui en découlent et leurs suites sont soumis au droit français.</p>

			<p>11.2. Juridiction compétente</p>

			<p>
				A défaut d&rsquo;accord amiable, il est de convention expresse que tout litige relatif au contrat sera de la compétence exclusive du tribunal de commerce de
				Metz, même en cas de demande incidente, d&rsquo;appel en garantie ou de pluralité de défendeurs.
			</p>

			<p><strong>XII. Clause de sauvegarde</strong></p>

			<p>
				La nullité totale ou partielle de certaines clauses du présent contrat n&rsquo;affecte pas la validité des autres clauses ou des stipulations valables
				contenues dans les clauses partiellement invalidées. Une clause ou stipulation frappée de nullité sera remplacée, d&rsquo;un commun accord entre les
				Parties, par une disposition contractuelle valable, aussi proche que possible de la portée économique de la clause ou stipulation invalidée.
			</p>

			<p><strong>XIII. Traitement des données à caractère personnel</strong></p>

			<p>
				Dans le cadre des relations contractuelles régies par les présentes conditions générales de vente, des données à caractère personnel des salariés,
				collaborateurs et clients du Vendeur et de l&rsquo;Acheteur peuvent être traitées.
			</p>

			<p>
				Chaque Partie reconnaît être tenue aux obligations respectives qui lui incombent en vertu des lois applicables en matière de protection de données
				personnelles et apportera son concours à l&rsquo;autre dans l&rsquo;exécution de ses obligations. A cet égard, l&rsquo;Acheteur déclare par ailleurs,
				concernant les traitements de données à caractère personnel qu&rsquo;il met en &oelig;uvre, être conforme à toute réglementation applicable relative à la
				protection de la vie privée et des données personnelles, et notamment la loi n&deg; 78-17 du 6 janvier 1978 relative à l&rsquo;informatique, aux fichiers et
				aux libertés et le Règlement UE 2016/679 du Parlement européen et du Conseil du 27 avril 2016, ci- après désigné le &laquo;RGPD&raquo;.
			</p>

			<p>
				L&rsquo;Acheteur déclare avoir pris connaissance de la notice d&rsquo;information relative à la protection des données à caractère personnel applicables aux
				partenaires commerciaux du Vendeur et reconnaît avoir obtenu toutes les informations relatives aux traitements de données mis en &oelig;uvre par le Vendeur
				conformément à l&rsquo;article 13 du RGPD.
			</p>
		</div>

		<Pieddepage :page="6" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page8",
	components: { Pieddepage },
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page8 {
	.data {
		address {
			text-align: right;
		}
	}
	.deux-colonnes {
		margin-top: 40px;
		column-count: 2;
		column-gap: 100px;
		font-size: 16px;
		line-height: 21px;
		text-align: justify;
		strong {
			margin-top: 10px;
			display: block;
		}
	}
}
</style>

<template>
	<div class="user" v-if="$store.state.user && $store.state.user.fields.role">
		<div class="top">
			<div class="logo" id="logoforpdf">
				<img src="../assets/images/logos/viessmann_wordmark_rgb_1_vitorange.png" />
			</div>

			<div class="role selector visibleforadmin" v-if="$router.currentRoute.path === '/' || $router.currentRoute.path === '/suspects'">
				<label>Secteur</label>
				<Selector />
			</div>
		</div>

		<div class="sep"></div>
		<div class="main_menu">
			<router-link to="/">
				<i class="fal fa-users"></i>
				<div class="right">
					<span>Clients</span>
					<span>Signature des PACs</span>
				</div>
			</router-link>
			<!-- <router-link to="/suspects">
				<i class="fal fa-user-lock"></i>
				<div class="right">
					<span>Suspects</span>
					<span>Gestion de vos suspects</span>
				</div>
			</router-link>
			<router-link to="/suspects-sap" v-if="$store.state.user.fields.role === 'administrateur'">
				<i class="fal fa-folder-open"></i>
				<div class="right">
					<span>Suspects SAP</span>
					<span>Validation des suspetcs (réservé SAP)</span>
				</div>
			</router-link> -->
			<a :href="lien_statistiques" target="_blank">
				<i class="fal fa-chart-pie"></i>
				<div class="right">
					<span>Statistiques</span>
					<span>Chiffres en un coup d'oeil</span>
				</div>
			</a>
			<router-link to="/moncompte">
				<i class="fal fa-cog"></i>
				<div class="right">
					<span>Mon compte</span>
					<span>Gérez votre mot de passe</span>
				</div>
			</router-link>
			<div class="link" @click="disconnect()">
				<i class="fal fa-sign-out"></i>
				<div class="right">
					<span>Déconnexion</span>
					<span>Supprimez vos données</span>
				</div>
			</div>
		</div>

		<div class="sep"></div>
		<div class="infos">
			<div class="role">
				<label>Role</label>
				{{ $store.state.user.fields.role.toUpperCase() }}
			</div>

			<div class="role">
				<label>Email</label>
				{{ $store.state.user.fields.email }}
			</div>
			<div class="role">
				<label>Tel</label>
				{{ $store.state.user.fields.telephone }}
			</div>
			<div class="role">
				<label>Wildcard</label>
				{{ $store.state.user.fields.wild_card ? $store.state.user.fields.wild_card : "0" }}
				restante(s)
			</div>
			<div class="role">
				<label>Clients</label>
				{{ $store.state.clients.length }}
			</div>
		</div>
	</div>
</template>
<script>
import Selector from "../components/secteurselector";
export default {
	name: "User",
	components: { Selector },
	data() {
		return {};
	},
	computed: {
		lien_statistiques() {
			return this.$store.state.lien_statistiques;
		}
	},
	methods: {
		disconnect() {
			this.$store.dispatch("disconnect");
			setTimeout(() => {
				this.$router.push("/connexion");
			}, 100);
		},
		toggle_clients() {
			this.$store.commit("toggleClient");
		}
	}
};
</script>

<style lang="scss" scoped>
#hide_clients {
	font-size: 12px;
	padding: 6px 8px;
	border-radius: 4px;
	font-weight: 500;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;
	cursor: pointer;
	border: none;
	background: rgba(black, 0.05);
	outline: none;
	margin-bottom: 10px;
	i,
	svg {
		margin-right: 10px;
	}
	@media screen and (max-width: $screen-phone) {
		display: none;
	}
}
.user {
	border-right: solid 1px rgba(black, 0.05);
	padding: 15px;
	font-weight: 300;
	background: white;
	color: $black;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	-webkit-overflow-scrolling: touch;
	@media screen and (max-width: $screen-phone) {
		overflow-y: visible;
	}
	.helpplease {
		position: absolute;
		bottom: max(100px, env(safe-area-inset-bottom));
		font-size: 12px;
		display: flex;
		color: black;
		background: rgba(black, 0.05);
		border-radius: 50px;
		padding: 10px;
		left: 10px;
		right: 10px;
		i {
			font-size: 20px;
			flex: 0 0 auto;
		}
		span {
			display: flex;
			flex-direction: column;
			font-weight: bold;
			small {
				font-weight: normal;
			}
		}
		&:hover {
			background: rgba($rouge, 0.1);
			color: $rouge;
		}
	}
	.sep {
		width: 100%;
		height: 1px;
		background: rgba(black, 0.05);
		margin: 20px 0;
	}
	.logo {
		position: relative;
		img {
			max-width: 160px;
			width: 100%;
			height: auto;
			margin-left: -10px;
			margin-bottom: 5px;
		}
	}
	.email,
	.telephone {
		display: flex;
		align-items: center;
		padding: 2px 4px;
		i,
		svg {
			font-size: 14px;
			color: $rouge;
			margin-right: 4px;
		}
	}
	.telephone {
		margin-bottom: 10px;
	}
	.role {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		font-weight: normal;
		flex-direction: column;
		font-size: 12px;
		padding: 2px 4px;
		margin-bottom: 4px;
		&:last-of-type {
			margin-bottom: 20px;
		}
		label {
			font-weight: 300;
			font-size: 10px;
			text-transform: uppercase;
		}
		&.selector {
			//background: rgba(black, 0.03);
			padding: 6px 10px;
			border-radius: 3px;
			margin-bottom: 0;
			width: 100%;
			margin-left: -3px;
		}
	}
	.secteur {
		display: flex;
		align-items: center;
		font-weight: normal;
		font-size: 13px;
		padding: 2px 4px;
		margin-bottom: 10px;
		label {
			font-weight: bold;
			font-size: 9px;
			text-transform: uppercase;
			flex: 0 0 60px;
		}
	}
	a,
	.link {
		color: lighten(black, 20%);
		font-size: 14px;
		padding: 10px;
		border-radius: 12px;
		display: flex;
		align-items: center;
		transition: all 0.3s ease;
		cursor: pointer;
		margin-bottom: 5px;
		z-index: 1;
		&:hover,
		&.router-link-exact-active {
			z-index: 20;
			box-shadow: 0 0 20px $fond;
			color: black;
			i,
			svg {
				color: $bleu;
			}
		}
		.right {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding-left: 12px;
			span {
				font-size: 14px;
				font-weight: 500;
				&:nth-child(2) {
					font-size: 10px;
					color: lighten(black, 50%);
					line-height: 13px;
					font-weight: 300;
				}
			}
		}
		i,
		svg {
			font-size: 22px;
			flex: 0 0 24px;
		}
		&.phoneonly {
			display: none;
		}
	}
	@media screen and (max-width: $screen-phone) {
		width: 100%;
		background: white;
		display: flex;
		padding: 0 0 0 15px;
		justify-content: flex-end;
		flex-direction: row;
		.name,
		.email,
		.telephone,
		.role,
		.secteur {
			display: none;
			&.visibleforadmin {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				background: white;
				padding: 5px 15px max(10px, env(safe-area-inset-bottom)) 15px;
			}
		}
		.logo {
			position: absolute;
			left: 0;
			bottom: auto;
			img {
				width: auto;
				height: 50px;
				margin: 0;
			}
		}
		a,
		.link {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;
			font-size: 20px;
			&.phoneonly {
				display: flex;
			}
			span {
				display: none;
			}
		}
	}
}
</style>

<template>
	<div class="pageprint" id="page11">
		<div id="mentionbas">
			6198602 FR 01/02/2024
			<br />Sous réserve de modifications techniques et règlementaires
		</div>
		<div id="mentionscotes">
			Viessmann France S.A.S. au capital de 1 700 000 € - BP 33 - 57380 Faulquemont - SIREN 493391114 - RCS Metz 2006 B 1345
		</div>
	</div>
</template>
<script>
export default {
	name: "page11",
	components: {},
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page11 {
	background: $rouge !important;
	#mentionbas {
		color: white;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.4;
		position: absolute;
		bottom: 80px;
		left: 80px;
	}
	#mentionscotes {
		color: white;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.4;
		position: absolute;
		bottom: 60%;
		transform-origin: bottom right;
		right: 120px;
		transform: rotateZ(-90deg);
	}
}
</style>

<template>
	<div class="box" id="signature">
		<div class="part attente" v-if="client.fields['statut'] === 'Envoyé'">
			<h2>Une procédure de signature est en cours.</h2>

			<p>
				Le lien de signature est : <br /><a :href="client.fields['lien_signature']" target="_blank">{{ client.fields["lien_signature"] }}</a>
			</p>
			<button @click="checkSignature()" v-html="after_check_message"></button>
			<br /><br />
			<button @click="cancelSignature">Annuler la procédure de signature</button>
		</div>
		<div
			class="part confirmation"
			v-if="
				(client.fields['statut'] === 'Signé' && client.fields['Classe de partenaire'].includes('Proactif')) ||
					(client.fields['statut'] === 'Signé' && client.fields['Classe de partenaire'].includes('Partenaire'))
			"
		>
			<i class="fad fa-check"></i>
			<h2>Félicitations ! <br />Le contrat est signé.</h2>
			<button @click="downloadSignedDocument()">
				Télécharger le contrat signé
			</button>
		</div>

		<div class="part confirmation" v-if="client.fields['statut'] === 'Prêt à signer' && ferme">
			<i class="fal fa-sad-tear"></i>
			<h2>C'est fini ! <br />Vous ne pouvez plus signer de PAC.</h2>
		</div>

		<div class="part confirmation" v-if="client.fields['statut'] === 'Brouillon'">
			<i class="fad fa-sad-cry"></i>
			<h2>Désolé! <br />Le statut "Brouillon" ne vous permet pas de signer le PAC</h2>
		</div>

		<!-- <div class="part confirmation" v-if="client.fields['statut'] === 'En attente de validation'">
			<i class="fal fa-sad-tear"></i>
			<h2>Désolé! <br />Le statut "En attente de validation" ne vous permet pas de signer le PAC</h2>
		</div> -->

		<!-- <div class="part confirmation" v-if="client.fields['statut'] === 'Refusé'">
			<i class="fal fa-sad-tear"></i>
			<h2>Désolé, <br />la signature a été refusée pour ce client.</h2>
		</div> -->

		<div class="part confirmation" v-if="client.fields['statut'] === 'Signé' && client.fields['Classe de partenaire'].includes('Prospect')">
			<i class="fad fa-check"></i>
			<h2>Vous avez validé ce client Basic <br />comme Prospect</h2>
			<van-cell center title="Vous voulez annuler ?">
				<template #right-icon>
					<van-switch v-model="change_to_prospect_and_validate" active-color="#558b2f" size="24" @change="handleProspectStatut()" />
				</template>
			</van-cell>
		</div>

		<div class="part" v-if="client.fields['statut'] === 'Prêt à signer' && !ferme">
			<h2>Signature du PAC</h2>
			<h3>Acceptation</h3>
			<van-cell center title="J'ai pris connaissance et j'accepte les conditions générales de vente et de garantie VIESSMANN">
				<template #right-icon>
					<van-switch v-model="conditions_ok" active-color="#558b2f" size="20" />
				</template>
			</van-cell>

			<van-cell center title="J'ai pris connaissance et j'accepte la charte de partenariat VIESSMANN">
				<template #right-icon>
					<van-switch v-model="charte_de_partenariat_ok" active-color="#558b2f" size="20" />
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields['Classe de partenaire'].includes('Proactif')"
				center
				title="Je suis intéressé pour participer à la convention nationale Proactif 2024"
			>
				<template #right-icon>
					<van-switch
						v-model="client.fields.challenge_pompe_chaleur"
						active-color="#558b2f"
						size="20"
						@change="handleChange($event, 'challenge_pompe_chaleur')"
					/>
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields['Classe de partenaire'].includes('Proactif')"
				center
				title="J'ai pris connaissance et j'accepte les conditions générales du réseau Proactif"
			>
				<template #right-icon>
					<van-switch v-model="conditions_proactifs_ok" active-color="#558b2f" size="20" />
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields['Classe de partenaire'].includes('(MIP)')"
				center
				title="J'adhère au Réseau Proactif et à tous ses avantages et je m'acquitte de la cotisation annuelle de 325€ HT"
			>
				<template #right-icon>
					<van-switch v-model="adhesion_ok" active-color="#558b2f" size="20" />
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields['Classe de partenaire'].includes('(MI+)')"
				center
				title="J'adhère au Réseau Proactif et à tous ses avantages et je m'acquitte de la cotisation annuelle de 950€ HT"
			>
				<template #right-icon>
					<van-switch v-model="adhesion_ok" active-color="#558b2f" size="20" />
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')"
				center
				title="Je m'engage à répondre dans les 48h à la proposition de prise en charge des projets que Viessmann m'enverra, contacter rapidement le client final, renseigner le processus d'évolution de ces demandes jusqu'à leur conversion en vente."
			>
				<template #right-icon>
					<van-switch v-model="client.fields['Engagement 48h']" active-color="#558b2f" size="20" @change="handleChange($event, 'Engagement 48h')" />
				</template>
			</van-cell>

			<van-cell
				v-if="client.fields.lead_management && (client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire'))"
				center
				title="J’ai pris connaissance et j'accepte les termes et conditions de la charte lead management"
			>
				<template #right-icon>
					<van-switch v-model="client.fields.lead_management_accepte" active-color="#558b2f" size="20" @change="handleChange($event, 'lead_management_accepte')" />
				</template>
			</van-cell>


			<div class="sep"></div>
		</div>

		<div class="part" v-if="client.fields['statut'] === 'Prêt à signer' && !ferme">
			<div v-if="client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')">
				<h3>Le signataire</h3>
				<div class="data">
					<label>Nom</label>
					<input type="text" max-length="20" v-model="nom_signataire" @change="handleChange($event, 'Nom signataire')" />
				</div>
				<div class="data">
					<label>Prénom</label>
					<input type="text" max-length="20" v-model="prenom_signataire" @change="handleChange($event, 'Prénom signataire')" />
				</div>

				<div class="data">
					<vue-phone-number-input
						:only-countries="['FR', 'BE', 'LU', 'ES']"
						:translations="translations"
						v-model="telephone_signataire"
						default-country-code="FR"
						@update="handlePhone($event)"
						color="#558b2f"
					/>
				</div>
				<div class="data">
					<label>Email</label>
					<input type="email" max-length="40" v-model="email_signataire" @keyup="verifemail()" @change="handleChange($event, 'Email signataire')" />
					<div class="alert_email_signataire" v-if="!email_signataire_ok">
						L'email n'est pas valide
					</div>
				</div>

				<div class="data" v-if="$store.state.current_client.fields['Chiffre affaires objectif théorique'] < 1">
					<label><i class="fal fa-exclamation-triangle"></i> Chiffres d'affaire théorique</label>
					<span>{{ $store.state.current_client.fields["Chiffre affaires objectif théorique"] | currency }}</span>
				</div>

				<div class="data" v-if="$store.state.current_client.fields['Chiffre affaires objectif convenu'] < 1">
					<label><i class="fal fa-exclamation-triangle"></i> Chiffres d'affaire convenu</label>
					<span>{{ $store.state.current_client.fields["Chiffre affaires objectif convenu"] | currency }}</span>
				</div>
				<div class="end" v-if="ready_to_sign">
					<van-cell center title="Je ne suis pas avec le client.">
						<template #right-icon>
							<van-switch v-model="procedure_mail" active-color="#558b2f" size="20" />
						</template>
					</van-cell>
					<p v-if="procedure_mail">
						Dans ce mode, le client reçoit un email à l'adresse renseignée avec un lien lui permettant de signer de manière autonome. A chaque fois que vous
						lancerez l'application, le système vérifiera si le client a bien signé le contrat.
					</p>
					<button type="submit" @click.prevent="handleSignature()">
						<div v-if="client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')">
							Procéder à la signature
						</div>
						<div v-else>Je valide ce client comme prospect <span>et l’inscrit au challenge conquête pompe à chaleur</span></div>
					</button>
				</div>
			</div>
			<div v-if="client.fields['Classe de partenaire'].includes('Prospect') || client.fields['Classe de partenaire'].includes('Basic')">
				<van-cell center title="Je valide ce client comme prospect et l’inscrit au challenge conquête pompe à chaleur">
					<template #right-icon>
						<van-switch v-model="change_to_prospect_and_validate" active-color="#558b2f" size="20" @change="handleProspectStatut()" />
					</template>
				</van-cell>
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { Dialog } from "vant";
import { version } from "../../../package.json";
import { mapActions } from "vuex";
export default {
	name: "Signature",
	components: {},
	data() {
		return {
			ferme: false,
			procedure: "Commencer la procédure de signature",
			procedure_mail: false,
			conditions_ok: true,
			charte_de_partenariat_ok: true,
			charte_proactif_ok: true,
			conditions_proactifs_ok: true,
			adhesion_ok: true,
			nom_signataire: "",
			prenom_signataire: "",
			telephone_signataire: "",
			email_signataire: "",
			email_signataire_ok: false,
			iframe_ready: false,
			iframe_url: "",
			timer: "",
			after_check_message: "Vérifier le statut de la signature",
			change_to_prospect_and_validate: false,
			telephone: {},
			pac: this.$store.state.current_client.fields.pac_objectif || 0,
			clim: this.$store.state.current_client.fields.clim_objectif || 0,
			thermodynamique: this.$store.state.current_client.fields.thermodynamique_objectif || 0,
			murales: this.$store.state.current_client.fields.murales_objectif || 0,
			fioul: this.$store.state.current_client.fields.fioul_objectif || 0,
			gaz: this.$store.state.current_client.fields.gaz_objectif || 0,
			mgp: this.$store.state.current_client.fields.mgp_objectif || 0,
			solaire: this.$store.state.current_client.fields.solaire_objectif || 0,
			pv: this.$store.state.current_client.fields.pv_objectif || 0,
			bois: this.$store.state.current_client.fields.bois_objectif || 0,
			vitoset: this.$store.state.current_client.fields.vitoset_objectif || 0,
			convenu: this.$store.state.current_client.fields["Chiffre affaires objectif convenu"] || 0,
			translations: {
				countrySelectorLabel: "Code pays",
				countrySelectorError: "Choisir un pays",
				phoneNumberLabel: "Numéro de téléphone",
				example: "Exemple :"
			}
		};
	},
	computed: {
		ready_to_sign() {
			let reponse = false;
			if (this.$store.state.current_client.fields["Classe de partenaire"].includes("Proactif")) {
				if (
					this.conditions_ok &&
					this.charte_de_partenariat_ok &&
					this.charte_proactif_ok &&
					this.conditions_proactifs_ok &&
					this.adhesion_ok &&
					this.nom_signataire &&
					this.prenom_signataire &&
					this.telephone.isValid &&
					this.email_signataire &&
					this.email_signataire_ok
				) {
					reponse = true;
				}
			} else {
				if (
					this.conditions_ok &&
					this.charte_de_partenariat_ok &&
					this.nom_signataire &&
					this.prenom_signataire &&
					this.telephone.isValid &&
					this.email_signataire
				) {
					reponse = true;
				}
			}
			return reponse;
		},
		client() {
			return this.$store.state.current_client;
		}
	},
	mounted() {
		this.nom_signataire = this.$store.state.current_client.fields["Nom signataire"];
		this.prenom_signataire = this.$store.state.current_client.fields["Prénom signataire"];
		this.telephone_signataire = this.$store.state.current_client.fields["Tél signataire"];
		this.email_signataire = this.$store.state.current_client.fields["Email signataire"];
		if (this.client.fields["statut"] == "Signé" && this.client.fields["Classe de partenaire"] == "Prospect (EK)") {
			this.change_to_prospect_and_validate = true;
		}
		this.verifemail();
		if (this.$store.state.current_client.fields.statut === "Brouillon") {
			this.calculTotal();
		}
		let now = moment();
		if (now.isAfter("2024-04-06") && this.$store.state.user.fields.email != "biec@viessmann.com") {
			this.ferme = true;
		}
	},
	methods: {
		verifVide() {
			if (this.pac.length === 0) {
				this.pac = 0;
			}
			if (this.clim.length === 0) {
				this.clim = 0;
			}
			if (this.thermodynamique.length === 0) {
				this.thermodynamique = 0;
			}
			if (this.murales.length === 0) {
				this.murales = 0;
			}
			if (this.fioul.length === 0) {
				this.fioul = 0;
			}
			if (this.gaz.length === 0) {
				this.gaz = 0;
			}
			if (this.mgp.length === 0) {
				this.mgp = 0;
			}
			if (this.solaire.length === 0) {
				this.solaire = 0;
			}
			if (this.pv.length === 0) {
				this.pv = 0;
			}
			if (this.bois.length === 0) {
				this.bois = 0;
			}
			if (this.vitoset.length === 0) {
				this.vitoset = 0;
			}
		},
		calculTotal() {
			this.verifVide();
			this.total =
				parseInt(this.pac) * 5000 +
				parseInt(this.clim) * 1100 +
				parseInt(this.thermodynamique) * 1200 +
				parseInt(this.murales) * 1800 +
				parseInt(this.fioul) * 2600 +
				parseInt(this.gaz) * 3500 +
				parseInt(this.mgp) * 6500 +
				parseInt(this.solaire) * 475 +
				parseInt(this.pv) * 800 +
				parseInt(this.bois) * 4500 +
				parseInt(this.vitoset);
			this.total = this.total * 1.32;
			this.total = Math.round(this.total, 2);
			this.$store.commit("updateStoreField", {
				field: "Chiffre affaires objectif théorique",
				value: parseInt(this.total)
			});
			if (this.convenu <= 0) {
				this.convenu = this.total;
				this.$store.commit("updateStoreField", {
					field: "Chiffre affaires objectif convenu",
					value: parseInt(this.convenu)
				});
			}
		},
		handlePhone(payload) {
			this.telephone = payload;
			this.$store.commit("updateStoreField", {
				field: "Tél signataire",
				value: payload.formattedNumber
			});
		},
		handleProspectStatut() {
			if (this.change_to_prospect_and_validate) {
				this.$store.commit("updateStoreField", {
					field: "Classe de partenaire",
					value: "Prospect (EK)"
				});
				this.$store.commit("updateStoreField", {
					field: "statut",
					value: "Signé"
				});
				this.$store.commit("updateStoreField", {
					field: "Objectif visites",
					value: 24
				});
				this.$store.commit("updateClientStatus", {
					client: this.client.id,
					statut: "Signé"
				});
				this.$store.commit("updateStoreField", {
					value: version,
					field: "app_version"
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: "Classe de partenaire",
					value: "Basic (PAR)"
				});
				this.$store.commit("updateStoreField", {
					field: "statut",
					value: "Prêt à signer"
				});
				this.$store.commit("updateStoreField", {
					field: "Objectif visites",
					value: 0
				});
				this.$store.commit("updateClientStatus", {
					client: this.client.id,
					statut: "Prêt à signer"
				});
			}
		},
		dataURItoBlob(dataURI) {
			const byteString = atob(dataURI.split(",")[1]);
			const mimeString = dataURI
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			const bb = new Blob([ab], { type: mimeString });
			return bb;
		},
		downloadSignedDocument() {
			if (this.client.fields["url_pdf_signe"]) {
				const link = document.createElement("a");
				link.href = this.client.fields["url_pdf_signe"][0].url;
				link.setAttribute("target", "_blank");
				document.body.appendChild(link);
				link.click();
				link.remove();
			} else {
				this.$store
					.dispatch("downloadSignedDocument", {
						client: this.client,
						procedure: this.client.fields["id_procedure_signature"]
					})
					.then(url => {
						if (url.data != "false") {
							const link = document.createElement("a");
							link.href = url.data;
							link.setAttribute("target", "_blank");
							document.body.appendChild(link);
							link.click();
							link.remove();
						}
					});
			}
		},
		checkSignature() {
			this.$store
				.dispatch("checkSignature", this.client.fields["id_procedure_signature"])
				.then(res => {
					if (res.status === "active") {
						this.after_check_message = "Le document n'a pas encore été signé.";
						this.$store.commit("updateClientStatus", {
							client: this.client.id,
							statut: "Envoyé"
						});
					}
					if (res.status === "finished") {
						this.after_check_message = "Bravo ! La signature est finalisée.";
						this.$store.commit("updateStoreField", {
							field: "statut",
							value: "Signé"
						});
						this.$store.commit("updateClientStatus", {
							client: this.client.id,
							statut: "Signé"
						});
						this.downloadSignedDocument();
					}
					if (res.status === "expired" || res.status === "refused") {
						this.after_check_message = "La signature a échouée. Le PAC est repassé en brouillon pour que vous puissiez relancer la procédure.";
						this.$store.commit("updateStoreField", {
							field: "statut",
							value: "Brouillon"
						});
						this.$store.commit("updateClientStatus", {
							client: this.client.id,
							statut: "Brouillon"
						});
					}
					if (res.status === "draft") {
						this.after_check_message = "Hum ! Le statut de signature est en brouillon. C'est étrange.";
					}
					setTimeout(() => {
						this.after_check_message = "Vérifier le statut de la signature";
					}, 5000);
				})
				.catch(err => {
					console.log(err);
				});
		},
		handleSignature() {
			let cango = true;

			// if (
			// 	this.$store.state.current_client.fields["Chiffre affaires objectif theorique"] < 1 ||
			// 	this.$store.state.current_client.fields["Chiffre affaires objectif convenu"] < 1
			// ) {
			// 	Dialog.confirm({
			// 		title: "Confirmation requise",
			// 		message: "L'objectif théorique et/ou l'objectif convenu à 0,00€. Confirmez-vous ?",
			// 		confirmButtonText: "Oui",
			// 		cancelButtonText: "Non"
			// 	}).then(() => {
			// 		cango = true;
			// 	});
			// } else {
			// 	cango = true;
			// }

			if (cango) {
				this.$store.commit("generatorMode", true);
				this.$store.commit("setMessageGeneration", "Création du contrat au format PDF...");
				setTimeout(() => {
					this.createPDF()
						.then(doc => {
							document.body.classList.remove("printmode");
							clearInterval(this.timer);
							this.$store
								.dispatch("beginSignature", {
									doc: doc.output("datauristring"),
									numero: this.client.fields["Numéro client"],
									nom_client: this.client.fields["Société"],
									procedure: this.procedure_mail
								})
								.then(() => {
									if (this.procedure_mail) {
										this.$store.commit("setMessageGeneration", "Envoi du mail de signature au client");
										let params = new URLSearchParams();
										let client = this.client.fields["Email signataire"];
										let commercial = this.client.fields.email_commercial[0];
										params.append("lien", this.client.fields["lien_signature"]);
										params.append("commercial", commercial);
										params.append("client", client);
										params.append("service", "signature");
										axios.post("https://dev-serveur.fr/mailjet/viessmann/api.php", params)
											.then(() => {
												this.$store.commit("setMessageGeneration", "Mail envoyé avec succès");
												setTimeout(() => {
													this.$store.commit("generatorMode", false);
												}, 2000);
											})
											.catch(err => {
												this.$store.commit("setMessageGeneration", "Erreur lors de l'envoi du mail");
												console.log(err);
												setTimeout(() => {
													this.$store.commit("generatorMode", false);
												}, 2000);
											});
									} else {
										this.$store.commit("setMessageGeneration", "Opération finalisée. Nous allons afficher l'interface de signature.");
										setTimeout(() => {
											this.$store.commit("generatorMode", false);
											window.open(this.$store.state.current_client.fields["lien_signature"]);
										}, 2000);
									}
								});
						})
						.catch(err => {
							this.$store.commit("setMessageGeneration", "Erreur lors de la création de la procédure de signature");
							setTimeout(() => {
								this.$store.commit("generatorMode", false);
							}, 2000);
						});
				}, 200);
			}
		},
		createPDFsecours() {
			return new Promise(resolve => {
				window.scrollTo(0, 0);
				const doc = new jsPDF({
					orientation: "p",
					unit: "mm",
					format: "a4",
					putOnlyUsedFonts: true,
					compress: true
				});
				doc.text("Hello world!", 10, 10);
				var source = window.document.getElementsByTagName("body")[0];
				doc.fromHTML(source, 15, 15, {
					width: 180
				});
				resolve(doc);
			});
		},
		createPDF() {
			console.log("je rentre en mode print mode");
			return new Promise(resolve => {
				window.scrollTo(0, 0);
				document.body.classList.add("printmode");
				const doc = new jsPDF({
					orientation: "p",
					unit: "mm",
					format: "a4",
					putOnlyUsedFonts: true,
					compress: true
				});
				doc.addPage(); //Page 1
				doc.addPage(); //Page 2
				doc.addPage(); //Page 3
				doc.addPage(); //Page 4
				doc.addPage(); //Page 5
				doc.addPage(); //Page 6
				doc.addPage(); //Page 7
				doc.addPage(); //Page 8
				doc.addPage(); //Page 9

				// On récupére la page 1
				let page0 = document.querySelector("#page0");
				let page1 = document.querySelector("#page1");
				let page2 = document.querySelector("#page3");
				let page3 = document.querySelector("#page5");
				let page4 = document.querySelector("#page6");
				let page5 = document.querySelector("#page7");
				let page6 = document.querySelector("#page8");
				let page7 = document.querySelector("#page9");
				let page8 = document.querySelector("#page10");
				let page9 = document.querySelector("#page11");

				let page0_ready = false;
				let page1_ready = false;
				let page2_ready = false;
				let page3_ready = false;
				let page4_ready = false;
				let page5_ready = false;
				let page6_ready = false;
				let page7_ready = false;
				let page8_ready = false;
				let page9_ready = false;

				this.timer = setInterval(() => {
					if (
						page0_ready &&
						page1_ready &&
						page2_ready &&
						page3_ready &&
						page4_ready &&
						page5_ready &&
						page6_ready &&
						page7_ready &&
						page8_ready &&
						page9_ready
					) {
						resolve(doc);
					}
				}, 200);

				html2canvas(page0, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					height: 2088,
					background: "#FFFFFF",
					scale: 1.0,
					imageTimeout: 30000,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(1);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page0_ready = true;
					console.log("Page 0 OK");
				});

				html2canvas(page1, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					height: 2088,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(2);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page1_ready = true;
					console.log("Page 1 OK");
				});

				html2canvas(page2, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(3);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page2_ready = true;
					console.log("Page 2 OK");
				});

				html2canvas(page3, {
					windowWidth: 1512,
					windowHeight: 2088,
					backgroundColor: "#FFFFFF",
					imageTimeout: 30000,
					width: 1512,
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(4);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page3_ready = true;
					console.log("Page 3 OK");
				});

				html2canvas(page4, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(5);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page4_ready = true;
					console.log("Page 4 OK");
				});

				html2canvas(page5, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(6);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page5_ready = true;
					console.log("Page 5 OK");
				});

				html2canvas(page6, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(7);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page6_ready = true;
					console.log("Page 6 OK");
				});

				html2canvas(page7, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(8);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page7_ready = true;
					console.log("Page 7 OK");
				});

				html2canvas(page8, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(9);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page8_ready = true;
					console.log("Page 8 OK");
				});

				html2canvas(page9, {
					windowWidth: 1512,
					windowHeight: 2088,
					width: 1512,
					imageTimeout: 30000,
					backgroundColor: "#FFFFFF",
					height: 2088,
					scale: 1.0,
					scrollX: 0,
					scrollY: -window.scrollY
				}).then(canvas => {
					var base = canvas.toDataURL("image/png");
					doc.setPage(10);
					doc.addImage(base, "PNG", 0, 0, 210, 297);
					page9_ready = true;
					console.log("Page 9 OK");
				});
			});
		},
		verifemail() {
			if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email_signataire)) {
				this.email_signataire_ok = true;
			} else {
				this.email_signataire_ok = false;
			}
		},
		handleChange(e, field) {
			if (e.target) {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e.target.value
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e
				});
			}
		},
		...mapActions({
			cancelSignature: "cancelSignature"
		})
	}
};
</script>

<style lang="scss">
.alert_email_signataire {
	color: $rouge;
	position: absolute;
	right: 20px;
	bottom: -22px;
}
#signature {
	h2 {
		margin-top: 40px;
		&:first-of-type {
			margin-top: 0;
		}
	}
	.end {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
		p {
			margin: 20px 10px;
			font-weight: 300;
			background: rgba($fond, 0.1);
			margin: 0;
			padding: 20px;
		}
		button[type="submit"] {
			margin-top: 20px;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			text-align: center;
			font-weight: 500;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			line-height: 18px;
			padding: 15px 30px;
			span {
				font-weight: 300;
				font-size: 12px;
			}
		}
	}
	.van-cell {
		padding: 10px;
		&__title {
			font-size: 12px;
			line-height: 16px;
		}
	}
	.reponse_signature {
		display: block;
		font-size: 13px;
		margin-top: 10px;
	}

	.vue-phone-number-input {
		width: 100%;
		.select-country-container {
			margin-right: 8px;
			label {
				font-size: 11px;
				top: -18px;
				left: 0;
			}
			.country-selector__country-flag {
				top: 14px;
			}
			input {
				padding-top: 0;
				padding-right: 30px;
			}
			.country-selector__toggle {
				transform: scale(0.8);
			}
			.country-selector__list__item {
				color: $black;
			}
		}
		.input-tel {
			input {
				padding-top: 0px;
			}
			label {
				font-size: 11px;
				top: -18px;
				right: 0;
				text-align: right;
				left: auto;
			}
		}
	}
}
</style>

<template>
	<div id="validation_classification">
		<h3>Demandes à traiter</h3>
		<div class="validations" v-if="validations.length > 0">
			<div class="validation" v-for="validation in validations" :key="validation.id">
				<p>
					<b>{{ validation.fields.email_demandeur[0] }}</b> a demandé un changement de statut de <b>{{ validation.fields.classe_actuelle[0] }}</b> vers
					<b>{{ validation.fields.nouveau_statut }}</b> pour <b>{{ validation.fields["Société (from id_client)"][0] }}</b
					>.
				</p>
				<p>
					La raison invoqué est : <em>{{ validation.fields.motif_changement }}</em>
				</p>
				<div class="buttons">
					<button
						class="success"
						@click="acceptChangement({ id: validation.id, client: validation.fields.id_client[0], nouveau: validation.fields.nouveau_statut })"
					>
						<i class="fal fa-check"></i> Accepter
					</button>
					<button class="error" @click="refuseChangement(validation.id)">Refuser</button>
				</div>
			</div>
		</div>
		<div v-else>
			<p>Il n'y aucune demande à traiter</p>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "Validation",
	components: {},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			validations: "validations"
		})
	},
	methods: {
		...mapActions({
			getValidations: "getValidations",
			refuseChangement: "refuseChangement",
			acceptChangement: "acceptChangement"
		})
	},
	mounted() {
		this.getValidations().then(res => {
			console.log(res);
		});
	}
};
</script>

<style lang="scss">
#validation_classification {
	transition: all 0.3s ease;
	border-radius: 20px;
	position: relative;
	padding: 30px;
	background: rgba($bleu, 0.03);
	border-radius: 30px;
	margin-bottom: 30px;
	margin-top: 40px;
	line-height: 24px;
	font-size: 16px;
	&:hover {
		box-shadow: 0 0 50px rgba($fond, 0.4);
	}
	h3 {
		text-align: left;
		font-weight: 500;
		font-size: 20px;
		text-transform: none;
		margin-bottom: 10px;
		margin-top: 10px;
		color: $bleu;
	}
	.validations {
		font-weight: 300;
		padding: 0px;
		margin-bottom: 30px;
		.validation {
			padding: 20px 0;
			b {
				font-weight: 500;
				color: $bleu;
			}
			.buttons {
				display: flex;
				align-items: stretch;
				gap: 10px;
				margin-top: 15px;
				justify-content: flex-start;
				button {
					padding: 10px 20px;
					border: none;
					font-size: 10px;
					text-transform: uppercase;
					font-weight: 500;
					letter-spacing: 0.5px;
				}
				button.error {
					background: transparent;
					color: darken($fond, 30%);
					&:hover {
						color: $rouge;
					}
				}
				button.success {
					background: rgba($vertclair, 0.2);
					color: $vertclair;
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover {
						background: $vertclair;
						color: white;
					}
				}
			}
		}
	}
}
</style>

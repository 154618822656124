<template>
	<div class="new_dashboard">
		<div class="liste_clients" v-if="user">
			<img class="icone" src="../assets/images/logos/icone-bing.png" />
			<div class="search">
				<input type="text" v-model="searchTerm" @keyup="searchInData" placeholder="Client, Statut, Classification" />
			</div>
			<div class="first-line">
				<div>Numéro Client<span>et secteur</span></div>
				<div>Société<span>et Statut</span></div>
				<!-- <div v-if="user.fields.role != 'atc'">Demande<span>de signature</span></div> -->
				<!-- <div v-else>Demander<span>la signature</span></div> -->
				<!-- <div v-if="user.fields.role != 'atc'">Accorder<span>la signature</span></div> -->
				<!-- <div v-else>Accord<span>de signature</span></div> -->
				<!-- <div>Statut après signature</div> -->
			</div>

			<div v-for="client in local_clients" :key="client.id" :class="`client ${client.fields.statut_format}`">
				<div class="case numero">
					{{ client.fields["Numéro client"] }}
					<div class="secteur">{{ client.fields["Secteur commercial"] }}</div>
				</div>
				<div class="case nameandclasse" @click="chooseClient(client.id)">
					<div class="name">
						{{ client.fields["Société"] }}
					</div>
					{{ client.fields["Classe de partenaire"] }}

					<div class="statut">{{ client.fields.statut }}</div>
					<!-- <div class="classification" v-if="client.fields.statut != 'Refusé'">
						Statut après Signature : {{ client.fields["Classe de partenaire"] }}
						<span v-if="client.fields['Wild Card Proactif']"> - WilCard utilisé</span>
						<span v-if="client.fields['Classification (Matrice)'] === 'Silver' && client.fields.statut === 'En attente de validation'"
							><br />Souhaité par l'ATC: {{ client.fields.signature_demandee_statut }}</span
						>
					</div> -->
				</div>

				<!-- <div class="case avant">
					<span :class="`classification ${client.fields['Classification (Matrice)']}`"
						><i class="fad fa-medal"></i><sup>{{ client.fields["Classification (Matrice)"] }}</sup></span
					>
				</div> -->

				<!-- <div class="case apres">
					{{ client.fields["Classe de partenaire"] }}
				</div> -->

				<!-- <div class="case">
					<van-switch
						:size="16"
						active-color="#107c41"
						v-if="
							$store.state.user.fields.role === 'atc' &&
								client.fields.statut != 'Prêt à envoyer' &&
								client.fields.statut != 'Signé' &&
								client.fields.statut != 'Envoyé' &&
								client.fields.statut != 'Refusé' &&
								!client.fields.signature_autorisee
						"
						v-model="client.fields.signature_demandee"
						:loading="loading"
						@input="manageStateDemande($event, client.id, client.fields.statut, client.fields.signature_demandee, client.fields.signature_demandee_statut)"
					/>
					<span v-else>
						<i class="fad fa-check" v-if="client.fields.signature_demandee"></i>
						<i class="fad fa-times" v-else></i>
					</span>
					<div
						class="group"
						v-if="
							client.fields['Classification (Matrice)'] === 'Silver' &&
								(client.fields.statut === 'Brouillon' || client.fields.statut === 'En attente de validation') &&
								user.fields.wild_card > 0 &&
								$store.state.user.fields.role === 'atc'
						"
					>
						<label v-if="client.fields.statut === 'Brouillon'">Statut souhaité</label>
						<select v-if="client.fields.statut === 'Brouillon'" v-model="client.fields.signature_demandee_statut">
							<option>Choisissez</option>
							<option value="Proactif (MIP)">Proactif (MIP)</option>
							<option value="Partenaire (MAP)">Partenaire (MAP)</option>
						</select>
						<span v-if="client.fields['Classification (Matrice)'] === 'Silver' && client.fields.statut === 'En attente de validation'"
							>Souhaité : {{ client.fields.signature_demandee_statut }}</span
						>
					</div>
				</div>
				<div class="case">
					<van-switch
						:size="16"
						active-color="#107c41"
						v-if="$store.state.user.fields.role != 'atc' && client.fields.statut != 'Signé' && client.fields.statut != 'Envoyé'"
						v-model="client.fields.signature_autorisee"
						:loading="loading"
						@input="
							manageStateReponse(
								$event,
								client.id,
								client.fields.statut,
								client.fields.signature_demandee,
								client.fields.signature_autorisee,
								client.fields.signature_demandee_statut
							)
						"
					/>
					<span v-else>
						<i class="fad fa-check" v-if="client.fields.signature_autorisee"></i>
						<i class="fad fa-times" v-else></i>
					</span>
					<div
						class="button updateSilverToMIP"
						@click="updateSilverToMIP(client.id)"
						v-if="
							$store.state.user.fields.role != 'atc' &&
								user.fields.wild_card > 0 &&
								client.fields['Classification (Matrice)'] === 'Silver' &&
								client.fields['Classe de partenaire'] === 'Partenaire (MAP)' &&
								client.fields.statut != 'Signé' &&
								client.fields.statut != 'Envoyé' &&
								client.fields.statut != 'Refusé'
						"
					>
						Wildcard <span>Passer en MIP</span>
					</div>
				</div> -->
			</div>
			<div class="filtre-actif" v-if="filtre.length > 0">
				Vous filtrez actuellement les clients <span>{{ filtre }}</span>
			</div>
		</div>
	</div>
</template>
<script>
import DataTable from "vanilla-datatables";
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
export default {
	name: "Datatable-Client",
	props: ["filtre"],
	components: {},
	data() {
		return {
			local_clients: {},
			datatable: "",
			loading: false,
			searchTerm: ""
		};
	},
	computed: {
		...mapGetters({
			allClients: "allClients",
			message: "message",
			user: "getUser"
		})
	},
	watch: {
		filtre: function(newVal, oldVal) {
			if (newVal === "") {
				this.local_clients = this.allClients;
			} else {
				this.local_clients = this.allClients.filter(el => el.fields.statut != undefined && el.fields.statut_format === newVal);
			}
		}
	},
	mounted() {
		setTimeout(() => {
			this.local_clients = [...this.allClients];
		}, 3000);
	},
	methods: {
		handleChange(e, field) {
			if (e.target) {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e.target.value
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e
				});
			}
		},
		...mapMutations({
			updateStatut: "updateStatut",
			updateStatutForATC: "updateStatutForATC",
			updateSignatureDemande: "updateSignatureDemande"
		}),
		...mapActions({
			chooseClient: "chooseClient",
			cancelSignature: "cancelSignature",
			updateSilverToMIP: "updateSilverToMIP"
		}),
		manageStatePartenaireDemande(e, id) {
			this.updateSignatureDemande({
				id: id,
				statut: e.target.value
			});
		},
		manageStateDemande(e, id, statut, signature_demandee, statut_souhaite) {
			if (e === true) {
				if (statut_souhaite === "Proactif (MIP)") {
					this.user.fields.wild_card--;
					this.$store.commit("updateUser", {
						field: "wild_card",
						value: this.user.fields.wild_card
					});
				}
				this.updateStatutForATC({
					id: id,
					statut: "En attente de validation",
					signature_demandee: true,
					statut_souhaite: statut_souhaite
				});
			} else {
				if (statut_souhaite === "Proactif (MIP)") {
					this.user.fields.wild_card++;
					this.$store.commit("updateUser", {
						field: "wild_card",
						value: this.user.fields.wild_card
					});
				}
				this.updateStatutForATC({
					id: id,
					statut: "Brouillon",
					signature_demandee: false,
					statut_souhaite: statut_souhaite
				});
			}
		},
		manageStateReponse(e, id, statut, signature_demandee, signature_autorisee, statut_souhaite) {
			if (e === false) {
				this.updateStatut({
					id: id,
					statut: "Refusé",
					signature_demandee: signature_demandee,
					signature_autorisee: false
				});
			} else {
				this.updateStatut({
					id: id,
					statut: "Prêt à signer",
					signature_demandee: signature_demandee,
					signature_autorisee: true,
					statut_souhaite: statut_souhaite
				});
			}
		},
		searchInData() {
			let clients = document.querySelectorAll(".liste_clients .client");
			if (this.searchTerm.length >= 3) {
				clients.forEach(el => {
					let case1 = el.querySelector(".case.numero").textContent;
					let case2 = el.querySelector(".case.nameandclasse").textContent;
					let final_sentence = (case1 + case2)
						.toString()
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "");
					if (
						final_sentence.includes(
							this.searchTerm
								.toString()
								.toLowerCase()
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
						)
					) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			} else {
				clients.forEach(el => {
					el.classList.remove("hidden");
				});
			}
		}
	}
};
</script>

<style lang="scss">
.new_dashboard {
	padding: 30px 15px;
	.liste_clients {
		box-shadow: 0 0 50px rgba(#c0cdd9, 0.2);
		padding: 0;
		border-radius: 20px;
		position: relative;
		.icone {
			position: absolute;
			top: 0;
			left: 0;
			width: 100px;
			height: auto;
			transform: translate(20px, -50%);
			border-radius: 50%;
			z-index: 10;
			box-shadow: 4px 4px 20px rgb(16 124 65 / 5%);
		}
		.search {
			position: absolute;
			left: 80px;
			z-index: 9;
			top: 0;
			transform: translateY(-50%);
			input {
				box-shadow: 0px 0px 30px rgba($fond, 0.4);
				transition: all 0.3s ease;
				appearance: none;
				outline: none;
				border-radius: 20px;
				border: none;
				padding: 0 20px 0 60px;
				min-height: 40px;
				min-width: 340px;
				font-size: 12px;
				&:focus {
					box-shadow: 0px 0px 30px rgba($fond, 1);
				}
			}
			&:hover {
				&:before {
					color: $bleu;
				}
			}
		}
		.first-line {
			padding: 80px 0px 20px;
			display: flex;
			border-bottom: solid 2px rgba($fond, 0.1);
			font-size: 13px;
			text-align: left;
			font-weight: 600;
			align-items: center;
			div {
				padding: 0 20px;
				flex: 0 0 auto;
				display: flex;
				flex-direction: column;
				span {
					font-weight: 300;
					font-size: 12px;
					color: darken($fond, 10%);
				}
				&:nth-child(1) {
					flex: 0 0 16%;
				}
				&:nth-child(2) {
					flex: 1;
				}
				&:nth-child(3) {
					flex: 0 0 25%;
				}
				&:nth-child(4) {
					flex: 0 0 25%;
				}
				&:nth-child(5) {
					flex: 0 0 1%;
				}
			}
		}
		.filtre-actif {
			font-size: 12px;
			padding: 10px 20px;
			color: black;
			span {
				color: $bleu;
			}
		}
		.client {
			display: flex;
			font-weight: 400;
			color: black;
			align-items: stretch;
			&.enattentedevalidation {
				border-left: solid 3px #fff9c4;
			}
			&.prêtàsigner {
				border-left: solid 3px #dcedc8;
				.case {
					button {
						color: #558b2f;
					}
				}
			}
			&.brouillon {
				border-left: solid 3px $fond;
			}

			&.signe {
				border-left: solid 3px#107c41;
			}
			&.envoye {
				border-left: solid 3px rgba($bleu, 0.2);
				.case {
					button {
						color: $bleu;
					}
				}
			}
			&:hover {
				border-left-width: 10px;
				transition: border 0.3s ease;
			}
			div.case {
				border: none;
				padding: 15px;
				font-size: 13px;
				line-height: 18px;
				font-weight: 300;
				border-bottom: solid 1px rgba(black, 0.03);
				&:nth-child(1) {
					flex: 0 0 16%;
				}
				&:nth-child(2) {
					flex: 1;
				}
				&:nth-child(3) {
					flex: 0 0 25%;
					font-size: 12px;
					font-weight: 400;
					color: darken($fond, 10%);
					label {
						display: block;
					}
					select {
						border: solid 1px $fond;
						padding: 0 10px;
						border-radius: 4px;
						background: rgba($fond, 0.1);
						height: 26px;
					}
				}
				&:nth-child(4) {
					flex: 0 0 25%;
				}
				&:nth-child(5) {
					flex: 0 0 1%;
				}
				&.numero {
					font-weight: 500;
					.secteur {
						font-weight: 300;
						color: darken($fond, 10%);
					}
				}
				.button.updateSilverToMIP {
					background: rgba($bleufonce, 0.1);
					display: flex;
					flex-direction: column;
					margin-top: 10px;
					color: $bleufonce;
					line-height: 13px;
					gap: 0;
					font-size: 14px;
					padding: 5px 10px;
					border-radius: 6px;
					span {
						font-size: 8px;
					}
				}
				.name {
					font-size: 13px;
					font-weight: 600;
					color: black;
					display: flex;
					align-items: center;
					gap: 10px;
					.classification {
						display: flex;
						gap: 3px;
						i,
						svg {
							font-size: 20px;
						}
						&.Silver {
							color: silver;
						}
						&.Gold {
							color: gold;
						}
						&.Black {
							color: black;
						}
						&.Basic {
							color: burlywood;
						}
					}
				}
				.adresse {
					font-weight: 300;
				}
				.statut,
				.buttons {
					display: flex;
					gap: 10px;
				}
				button,
				.button {
					color: black;
					border: none;
					background: transparent;
					outline: none;
					font-size: 12px;
					display: flex;
					align-items: center;
					gap: 5px;
					font-weight: bold;
					cursor: pointer;
					transition: all 0.3s ease;
					&:hover {
						transform: translateY(2px);
					}
					i,
					svg {
						font-size: 16px;
					}
				}
				.fad.fa-check {
					color: $vert;
				}
				.fad.fa-times {
					color: $rouge;
				}
				.actions {
					color: $fond;
					position: relative;
					height: 100%;
					display: flex;
					align-items: center;
					padding: 10px;
					justify-content: flex-end;
					.dots {
						font-size: 18px;
						text-align: right;
					}
					.list_of_actions {
						position: absolute;
						top: 0;
						z-index: 10;
						right: 0;
						background: linear-gradient(lighten($fond, 80%), white);
						border: solid 1px rgba($fond, 0.2);
						border-radius: 20px;
						box-shadow: 0px 0px 40px rgba($fond, 0.6);
						padding: 10px 0;
						display: none;
						.action {
							padding: 10px 20px;
							white-space: nowrap;
							text-align: right;
							transition: all 0.3s ease;
							color: darken($fond, 40%);
							&:hover {
								background: rgba($fond, 0.1);
								cursor: pointer;
							}
						}
					}
					&:hover {
						.list_of_actions {
							display: block;
						}
					}
				}
				&.avant {
					.classification {
						display: flex;
						color: darken($fond, 10%);
						font-size: 16px;
						font-weight: 500;
						align-items: center;
						gap: 10px;
						&.Black {
							color: black;
						}
						&.Silver {
							color: silver;
						}
						&.Basic {
							color: $bleu;
						}
						&.Gold {
							color: gold;
						}
					}
				}
				&.apres {
					font-weight: 500;
					font-size: 14px;
					text-align: left;
				}
				&.nameandclasse {
					cursor: pointer;
					&:hover {
						.name {
							color: $rouge;
						}
					}
				}
			}
			&.hidden {
				display: none;
			}
		}
	}
}
</style>
